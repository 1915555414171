import React, { ReactElement } from 'react';

import {
  Grid,
  GridItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';

import { metricsStyles } from '@app/modules/common-ui/components/revenue-share-management/styles';
import { IPartnerMetricsProps } from './interfaces';
import { formatCommaSeperatedNumber, formatPrice } from '@app/modules/core/lvedHelpers';

export function PartnershipMetrics({
  tierData,
  subscribers,
  funds,
}: IPartnerMetricsProps): ReactElement {
  function dateAge(date: Date): string {
    const timeLeft = date.getTime() - new Date().getTime();
    return `${Math.round(timeLeft / (1000 * 60 * 60 * 24))} days`;
  }

  const totalSubscribers =
    subscribers.currentGroupAnnualSubscribers +
    subscribers.currentGroupMonthlySubscribers +
    subscribers.currentIndividualAnnualSubscribers +
    subscribers.currentIndividualMonthlySubscribers;

  const tierResetDate = new Date(Date.parse(tierData.tierResetISODate ?? ''));

  return (
    <Grid {...metricsStyles.gridContainer}>
      <GridItem area='tier' {...metricsStyles.gridItemBox}>
        <VStack width='100%' gap='4px'>
          <Text {...metricsStyles.sectionTitle}>Current Tier</Text>
          <Text {...metricsStyles.sectionValue}>{tierData.tier}</Text>
          <Text {...metricsStyles.subValue}>{tierData.maxDiscountPts}% off subscription</Text>
        </VStack>
      </GridItem>
      <GridItem area='resetDate' {...metricsStyles.gridItemBox}>
        <VStack width='100%' gap='4px'>
          <Text {...metricsStyles.sectionTitle}>Tier Valid Through</Text>
          <Text {...metricsStyles.sectionValueSm}>
            {tierResetDate.toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }) ?? 'Unknown'}
          </Text>
          <Text {...metricsStyles.subValue}>{dateAge(tierResetDate)} remaining</Text>
        </VStack>
      </GridItem>
      <GridItem area='subTotals' {...metricsStyles.gridItemBox}>
        <VStack width='100%' gap='4px'>
          <Text {...metricsStyles.sectionTitle}>Active Subscriptions</Text>
          <Text {...metricsStyles.sectionValue}>
            {formatCommaSeperatedNumber(totalSubscribers)}
          </Text>
          <VStack width='100%' gap='0px'>
            {tierData.subscribersInNextTier && (
              <Text {...metricsStyles.subValue}>
                Next tier: {formatCommaSeperatedNumber(tierData.subscribersInNextTier)} (+
                {formatCommaSeperatedNumber(tierData.subscribersInNextTier - totalSubscribers)}
                active subs)
              </Text>
            )}
            {subscribers.totalSubscribersMonthIncreasePct !== 0 && (
              <Text {...metricsStyles.subValue}>
                {subscribers.totalSubscribersMonthIncreasePct > 0 ? 'Up ' : 'Down '}
                {subscribers.totalSubscribersMonthIncreasePct * 100}% this month
              </Text>
            )}
          </VStack>
        </VStack>
      </GridItem>
      <GridItem area='subscriptions' {...metricsStyles.gridItemBox}>
        <VStack width='100%' gap='0px'>
          <Text {...metricsStyles.sectionTitle}>Active Subscriptions by type</Text>
          <TableContainer>
            <Table variant='unstyled' size='sm'>
              <Thead>
                <Tr>
                  <Th />
                  <Th>INDIVIDUAL</Th>
                  <Th>GROUP</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Annual subscriptions</Td>
                  <Td {...metricsStyles.subscriptionCounts}>
                    {formatCommaSeperatedNumber(subscribers.currentIndividualAnnualSubscribers)}
                  </Td>
                  <Td {...metricsStyles.subscriptionCounts}>
                    {formatCommaSeperatedNumber(subscribers.currentGroupAnnualSubscribers)}
                  </Td>
                </Tr>
                <Tr border='0px'>
                  <Td>Month to month</Td>
                  <Td {...metricsStyles.subscriptionCounts}>
                    {formatCommaSeperatedNumber(subscribers.currentIndividualMonthlySubscribers)}
                  </Td>
                  <Td {...metricsStyles.subscriptionCounts}>
                    {formatCommaSeperatedNumber(subscribers.currentGroupMonthlySubscribers)}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      </GridItem>
      <GridItem area='earnings' {...metricsStyles.gridItemBox}>
        <VStack width='100%' gap='4px'>
          <Text {...metricsStyles.sectionTitle}>Earnings Balance</Text>
          <Text {...metricsStyles.sectionValue}>{formatPrice(funds.accountBalance, true)}</Text>
          <Text {...metricsStyles.subValue}>
            Pending balance: {formatPrice(funds.pendingAccountBalance, true)}
          </Text>
        </VStack>
      </GridItem>
    </Grid>
  );
}

/*
{accountBalanceMonthIncreasePct !== 0 && (
  <Text {...metricsStyles.subValue}>
    {accountBalanceMonthIncreasePct > 0 ? 'Up ' : 'Down '}
    {accountBalanceMonthIncreasePct}% this month
  </Text>
)}
*/
