import React, { ReactElement } from 'react';

import { HStack, Text, VStack, Wrap } from '@chakra-ui/react';
import { blockLicenseStatsStyles } from './styles';
import {
  licenseBlockType,
  subscriptionDataType,
} from '@app/modules/core/aws/lambdas/dashboard/interfaces';
import { formatCommaSeperatedNumber } from '@app/modules/core/lvedHelpers';

export function BlockLicenseStats({
  needsApproval,
  subscribers,
  licensedSeats,
}: {
  needsApproval?: number;
  subscribers: subscriptionDataType;
  licensedSeats: licenseBlockType;
}): ReactElement {
  const totalSubs =
    subscribers.currentGroupAnnualSubscribers +
    subscribers.currentGroupMonthlySubscribers +
    subscribers.currentIndividualAnnualSubscribers +
    subscribers.currentIndividualMonthlySubscribers;
  return (
    <HStack width='100%' justifyContent='right'>
      <Wrap height='100%'>
        {needsApproval && (
          <VStack gap='4px' {...blockLicenseStatsStyles.box}>
            <Text {...blockLicenseStatsStyles.sectionTitle}>Needs Approval</Text>
            <Text {...blockLicenseStatsStyles.sectionValue}>
              {formatCommaSeperatedNumber(needsApproval!)}
            </Text>
          </VStack>
        )}
        <VStack gap='4px' {...blockLicenseStatsStyles.box}>
          <Text {...blockLicenseStatsStyles.sectionTitle}>Total Subscribers</Text>
          <Text {...blockLicenseStatsStyles.sectionValue}>
            {formatCommaSeperatedNumber(totalSubs)}
          </Text>
        </VStack>
        <VStack gap='4px' {...blockLicenseStatsStyles.box}>
          <Text {...blockLicenseStatsStyles.sectionTitle}>Seats Remaining</Text>
          <Text {...blockLicenseStatsStyles.sectionValue}>
            {licensedSeats.areSeatsUnlimited
              ? 'Unlimited'
              : formatCommaSeperatedNumber(licensedSeats.licensedSeats)}
          </Text>
        </VStack>
      </Wrap>
    </HStack>
  );
}
