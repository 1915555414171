export const metricsStyles = {
  gridContainer: {
    templateAreas: {
      base: `"tier"
             "resetDate"
             "subscriptions"
             "subTotals"
             "earnings"`,
      lg: `"tier          resetDate     subTotals"
           "subscriptions subscriptions earnings"`,
    },
    gridTemplateRows: { base: '167px 167px 167px 167px 167px', lg: '167px 167px' },
    gridTemplateColumns: { base: '395px', lg: '240px 280px 240px' },
    gap: '10px',
    color: 'blackAlpha.700',
    fontWeight: 'bold',
  },

  gridItemBox: {
    backgroundColor: '#EFECE7',
    borderRadius: '0px',
    padding: '10px',
    width: '100%',
    height: '100%',
  },

  sectionTitle: {
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '0px',
    color: '#000000',
    width: '100%',
    justifyContent: 'left',
  },

  sectionValue: {
    fontSize: '43px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '51px',
    letterSpacing: '0px',
    color: '#045F60',
    width: '100%',
    justifyContent: 'left',
  },

  sectionValueSm: {
    fontSize: '40px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '48px',
    letterSpacing: '0px',
    color: '#045F60',
    width: '100%',
    justifyContent: 'left',
  },

  subValue: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '300',
    lineHeight: '16px',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '0.6',
    width: '100%',
    justifyContent: 'left',
  },

  subscriptionCounts: {
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '29px',
    letterSpacing: '0px',
    color: '#045F60',
    width: '100%',
    justifyContent: 'center',
  },
};

export const pricingContentStyles = {
  slider: {
    width: '160px',
    height: '50px',
    margin: '0px 10px 0px 20px',
  },

  sliderTrack: {
    bg: '#C0C0C0',
  },

  sliderFilledTrack: {
    bg: '#2E5D5F',
  },

  sliderThumb: {
    bg: '#2E5D5F',
  },

  leftTitle: {
    w: '220px',
    padding: '0px 0px 0px 0px',
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  subtitle: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '300',
    lineHeight: '18px',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '0.6',
    width: '220px',
  },

  resetText: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  errorText: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: 'red',
  },

  leftTitleForTable: {
    w: '220px',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  leftTitleForTableFocus: {
    w: '220px',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: 'red',
  },

  sliderMark: {
    mt: '6',
    ml: '-2.5',
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#000000',
  },

  button: {
    width: '237px',
    height: '50px',
    color: '#ffffff',
    backgroundColor: '#045f60',
    borderRadius: '25px',
    fontSize: '15px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '0px',
  },
};

export const promoContentStyles = {
  gridContainerWSignupLink: {
    padding: '10px',
    width: '100%',
    templateAreas: {
      base: `"promoCode"
             "signupLink"
             "download"`,
      lg: `"promoCode download"
           "signupLink signupLink"`,
    },
    gridTemplateRows: { base: '50px 50px 50px', lg: '50px 50px' },
    gridTemplateColumns: { base: '390px', lg: '300px 465px' },
    gap: { base: '10px', lg: '15px' },
    color: 'blackAlpha.700',
    fontWeight: 'bold',
  },

  gridContainerWOSignupLink: {
    padding: '10px',
    width: '100%',
    templateAreas: {
      base: `"promoCode"
             "download"`,
      lg: `"promoCode download"`,
    },
    gridTemplateRows: { base: '50px 50px', lg: '35px' },
    gridTemplateColumns: { base: '390px', lg: '300px 280px' },
    gap: { base: '10px', lg: '15px' },
    color: 'blackAlpha.700',
    fontWeight: 'bold',
  },

  gridItemBox: {
    borderRadius: '0px',
    padding: '10px',
    width: '100%',
    height: '100%',
  },

  affiliateLink: {
    width: '100%',
    justifyContent: 'left',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#045f60',
  },

  plainText: {
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: '300',
    letterSpacing: '0px',
  },

  editBox: {
    border: '1px',
    height: '50px',
    width: '100%',
    borderRadius: '4px',
    borderColor: '#101010',
    padding: '0px 6px 0px 8px',
  },

  promoTitle: {
    fontSize: '18px',
    lineHeight: '28px',
    fontFamily: 'Lato',
    fontWeight: '500',
    letterSpacing: '0px',
  },

  promoCode: {
    fontSize: '22px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '36px',
    height: '35px',
    letterSpacing: '0px',
    isTruncated: true,
    color: '#000000',
  },

  button: {
    width: '237px',
    height: '50px',
    color: '#ffffff',
    backgroundColor: '#045f60',
    borderRadius: '25px',
    fontSize: '15px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '0px',
  },

  copyImage: {
    w: '27px',
  },

  errorText: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '300',
    lineHeight: '20px',
    isTruncated: false,
    color: 'red',
  },
};

export const bequestSummaryStyles = {
  gridContainer: {
    padding: '4px',
    width: '100%',
    templateAreas: {
      base: ` "h1 h2 h3"
             "r11 r12 r13"
             "r21 r22 r23"
             "r31 r32 r33"
             "r41 r42 r43"`,
    },
    gridTemplateRows: { base: '48px 48px 28px 28px 48px', lg: '20px 20px 20px 20px' },
    gridTemplateColumns: { base: '190px 90px 90px', lg: '410px 180px 180px' },
    gap: { base: '8px', lg: '10px' },
  },

  gridItem: {
    // bg: 'blue',
  },

  tableHeader: {
    width: { base: '100%' },
    height: '100%',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '0px',
    color: '#000000',
    borderBottom: '1px',
    // isTruncated: false,
    // textwrap: 'wrap',
    // sx: {
    //  'text-wrap': 'wrap',
    //  'word-wrap': 'normal',
    // },
  },

  tableCol: {
    width: '100%',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '0px',
    color: '#000000',
    // isTruncated: false,
    // textwrap: 'wrap',
    // sx: {
    //  'text-wrap': 'wrap',
    //  'word-wrap': 'normal',
    // },
  },

  rowTitle: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '18px',
    letterSpacing: '0px',
    color: '#000000',
    width: '100%',
    // height: '200px',
    // noOfLines: { base: 2, lg: 1 },
    // isTruncated: false,
    // textwrap: 'wrap',
    // sx: {
    //  'text-wrap': 'wrap',
    //  'word-wrap': 'normal',
    // },
  },

  tableValue: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '0px',
    color: '#000000',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
  },

  errorText: {
    padding: '10px',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '0px',
    color: 'red',
    width: '100%',
  },

  container: {
    width: { base: '100%', lg: '800px' },
    justifyContent: 'middle',
    bg: '#EFECE7',
    margin: '0px',
    padding: '0px',
  },

  separator: {
    width: '100%',
    height: '2px',
    backgroundColor: 'gray.200',
  },

  title: {
    width: '100%',
    padding: '10px',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  header: {
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  value: {
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  greenText: {
    width: '60%',
    color: 'brand.green.100',
    justifyContent: 'end',
    textAlign: 'end',
  },

  fieldName: {
    fontSize: 'md',
    width: '150px',
  },
} as const;
