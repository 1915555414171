import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, UserKeysResType } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function updateUserProfile(info: Record<string, any>): Promise<UserKeysResType> {
  if (Object.keys(info).length === 0)
    return new Promise<{}>((resolve): void => {
      resolve(info);
    });
  const credentials: ICredentials = await Auth.currentCredentials();
  const clientDB: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const getPendingUpdatesParams = {
    TableName: DYNAMO_DB_TABLES.affiliateObjects,
    Key: { affiliateId: credentials.identityId },
    ProjectionExpression: 'pendingUpdates',
  };
  const pendingUpdatesRes = await clientDB.get(getPendingUpdatesParams).promise();
  const hasPendingUpdates = (pendingUpdatesRes?.Item?.pendingUpdates ?? undefined) !== undefined;

  const param = {
    TableName: DYNAMO_DB_TABLES.affiliateObjects,
    Key: { affiliateId: credentials.identityId },
    UpdateExpression: 'set pendingUpdates = :pendingUpdates',
    ExpressionAttributeValues: {
      ':pendingUpdates': info,
    },
  };
  if (hasPendingUpdates) {
    if ((pendingUpdatesRes!.Item!.pendingUpdates.length ?? 0) > 0)
      param.ExpressionAttributeValues[':pendingUpdates'] = [
        ...pendingUpdatesRes!.Item!.pendingUpdates,
        info,
      ];
    else
      param.ExpressionAttributeValues[':pendingUpdates'] = [
        pendingUpdatesRes!.Item!.pendingUpdates,
        info,
      ];
  }
  // console.log('Updating pending updates to: ');
  // console.log(param.ExpressionAttributeValues[':pendingUpdates']);

  return new Promise<{}>((resolve, reject): void => {
    clientDB.update(
      param,
      (err: AWSError, data: DynamoDB.DocumentClient.UpdateItemOutput): void => {
        if (err) {
          return reject(new Error(err.message || JSON.stringify(err)));
        }

        return resolve(data);
      }
    );
  });
}
