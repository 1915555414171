export const AuthHeaderStyles = {
  gridMaster: {
    templateColumns: 'repeat(6, 1fr)',
    w: '100%',
    bg: '#080809',
    position: 'fixed',
    zIndex: 9,
    transition: 'background-color 0.2s linear',
  },

  gridItemHorizontalNavigation: {
    colSpan: { base: 6 },
    display: { base: 'none', md: 'flex' },
  },

  gridItemVerticalNavigation: {
    colSpan: 6,
    display: { base: 'flex', md: 'none' },
  },

  logoImage: {
    width: '180px',
    height: '95px',
  },

  menuItem: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },

  verticalClosedContainer: {
    display: 'flex',
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
    spacing: '24px',
    alignContent: 'space-between',
    alignItems: 'center',
  },

  verticalOpenContainer: {
    display: 'flex',
    width: '100%',
    height: '300px',
    padding: '24px',
    spacing: '12px',
    alignContent: 'space-between',
    alignItems: 'center',
  },

  hamburgerMenuImage: {
    boxSize: '24px',
  },

  button: {
    bg: 'var(--clickable-white, #FFF)',
    display: 'flex',
    width: '131px',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    borderRadius: '1000px',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },

  buttonNoFill: {
    bg: '#000000',
    display: 'flex',
    width: '131px',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    borderRadius: '1000px',
    border: '2px solid var(--clickable-white, #FFF)',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
} as const;
