import { CognitoUserPool, ICognitoUserPoolData, CognitoUser } from 'amazon-cognito-identity-js';

function userPool(): CognitoUserPool {
  return new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
  } as ICognitoUserPoolData);
}

export function getUserFromUserName(userName: string): CognitoUser {
  return new CognitoUser({
    Username: userName.toLowerCase(),
    Pool: userPool(),
  });
}
