import React, { ReactElement, useEffect, useState } from 'react';

import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Wrap,
  VStack,
  HStack,
  Text,
  SliderMark,
  Box,
  TableContainer,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Button,
  Spacer,
} from '@chakra-ui/react';

// import { pricingContentStyles } from '@app/modules/common-ui/components/profile-dashboard/styles';
import { IPricingControlProps } from './interfaces';
import { pricingContentStyles } from './styles';
import { formatPrice } from '@app/modules/core/lvedHelpers/commontools';
import { activateOffer } from '@app/modules/core/aws/lambdas/activate-offer';
import { useQuery } from '@app/modules/core/hooks';
import { IActivateOfferRes } from '@app/modules/core/aws/lambdas/activate-offer/interfaces';

export function PricingControl({
  basePricing,
  currentPricingDiscountPts,
  maxDiscountPts,
  promoCode,
  // affiliateDiscount,
  onOfferUpdate,
}: IPricingControlProps): ReactElement {
  const hasOffer = (promoCode ?? '********') !== '********';
  const [discountPts, setDiscountPts] = useState<number>(currentPricingDiscountPts);
  const {
    submit: handleActivateOffer,
    res: updatedPromo,
    isLoading: isBusy,
    err: error,
  } = useQuery<number, IActivateOfferRes>(activateOffer);

  useEffect(() => {
    if (onOfferUpdate && updatedPromo) onOfferUpdate(updatedPromo);
  }, [updatedPromo]);

  return (
    <VStack w='100%' background='#F9F8F6' padding='10px'>
      <Wrap w='100%'>
        <HStack {...pricingContentStyles.leftTitle}>
          <Text w='70%'>Retained margin:</Text>
          <Text w='30%'>{maxDiscountPts - discountPts}%</Text>
        </HStack>
        <HStack>
          <Slider
            {...pricingContentStyles.slider}
            min={0}
            max={maxDiscountPts}
            step={1}
            value={discountPts}
            onChange={(val) => setDiscountPts(val)}
          >
            <SliderMark {...pricingContentStyles.sliderMark} value={0}>
              {maxDiscountPts}%
            </SliderMark>
            <SliderMark {...pricingContentStyles.sliderMark} value={maxDiscountPts}>
              0%
            </SliderMark>
            <SliderMark {...pricingContentStyles.sliderMark} value={100} />
            <SliderTrack {...pricingContentStyles.sliderTrack}>
              <SliderFilledTrack {...pricingContentStyles.sliderFilledTrack} />
            </SliderTrack>
            <SliderThumb {...pricingContentStyles.sliderThumb} />
          </Slider>
          <Text
            {...pricingContentStyles.resetText}
            onClick={() => {
              setDiscountPts(currentPricingDiscountPts);
            }}
          >
            Reset
          </Text>
        </HStack>
      </Wrap>
      <Box width='100%' height='1px' bg='#707070' opacity='0.2' />
      <Wrap w='100%'>
        <VStack h='100%'>
          <Text {...pricingContentStyles.leftTitleForTable}>Customers are charged*:</Text>
          <Spacer />
          <Text {...pricingContentStyles.subtitle}>
            *Pricing and retention based on LVED&apos;s current pricing and discounts and may change
            in the future
          </Text>
        </VStack>
        <TableContainer>
          <Table variant='unstyled' size='sm'>
            <Thead>
              <Tr>
                <Th>INDIVIDUAL</Th>
                <Th>GROUP</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr border='0px'>
                <Td>
                  {formatPrice((basePricing.annualIndividualPrice * (100 - discountPts)) / 100)}/yr
                </Td>
                <Td>
                  {formatPrice((basePricing.annualFamilyPrice * (100 - discountPts)) / 100)}/yr
                </Td>
              </Tr>
              <Tr>
                <Td>
                  {formatPrice((basePricing.monthlyIndividualPrice * (100 - discountPts)) / 100)}/mo
                </Td>
                <Td>
                  {formatPrice((basePricing.monthlyFamilyPrice * (100 - discountPts)) / 100)}/mo
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Wrap>
      <Box width='100%' height='1px' bg='#707070' opacity='0.2' />
      <Wrap w='100%'>
        <Text
          {...(discountPts > maxDiscountPts
            ? pricingContentStyles.leftTitleForTableFocus
            : pricingContentStyles.leftTitleForTable)}
        >
          Amount you {discountPts > maxDiscountPts ? 'pay' : 'receive'} per subscriber*:
        </Text>
        <TableContainer>
          <Table variant='simple' size='sm'>
            <Thead>
              <Tr>
                <Th>INDIVIDUAL</Th>
                <Th>GROUP</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr border='0px'>
                <Td>
                  {formatPrice(
                    (basePricing.annualIndividualPrice * (maxDiscountPts - discountPts)) / 100
                  )}
                  /yr
                </Td>
                <Td>
                  {formatPrice(
                    (basePricing.annualFamilyPrice * (maxDiscountPts - discountPts)) / 100
                  )}
                  /yr
                </Td>
              </Tr>
              <Tr>
                <Td>
                  {formatPrice(
                    (basePricing.monthlyIndividualPrice * (maxDiscountPts - discountPts)) / 100
                  )}
                  /mo
                </Td>
                <Td>
                  {formatPrice(
                    (basePricing.monthlyFamilyPrice * (maxDiscountPts - discountPts)) / 100
                  )}
                  /mo
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Wrap>
      <Button
        {...pricingContentStyles.button}
        isLoading={isBusy}
        isDisabled={hasOffer && currentPricingDiscountPts === discountPts}
        onClick={() => handleActivateOffer(discountPts)}
      >
        {hasOffer ? 'APPLY CHANGES' : 'CREATE OFFER'}
      </Button>
      {error && <Text {...pricingContentStyles.errorText}>{error.message}</Text>}
    </VStack>
  );
}
