import React, { ReactElement, useEffect, useState } from 'react';

import { Button, Grid, GridItem, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { validationTermType } from '@app/modules/core/aws/lambdas/dashboard/interfaces';
import { useQuery } from '@app/modules/core/hooks';
import {
  IActivateManualVerifiedOfferReq,
  IActivateOfferRes,
} from '@app/modules/core/aws/lambdas/activate-offer/interfaces';
import { useFormik } from 'formik';
import { manualApprovalValidationSchema } from './validation-schema';
import { activateManualVerifiedOffer } from '@app/modules/core/aws/lambdas/activate-offer';
import { licenseBlockManagerStyles } from './styles';
import { TextInput } from '../text-field';
import RadioTextInput from '../text-field/RadioTextInput';

export function ApproverEmail({
  validationTerm,
  approverEmail,
  onOfferUpdate,
}: {
  validationTerm?: validationTermType;
  approverEmail?: string;
  onOfferUpdate?: () => void;
}): ReactElement {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const {
    submit: handleActivateOffer,
    // res: updatedPromo,
    isLoading: isBusy,
    err: error,
  } = useQuery<IActivateManualVerifiedOfferReq, IActivateOfferRes>(activateManualVerifiedOffer);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: (data: IActivateManualVerifiedOfferReq) => {
      return handleActivateOffer(data).then(onOfferUpdate);
    },
    validationSchema: manualApprovalValidationSchema,
    initialValues: {
      approverEmail: approverEmail ?? '',
      validationTerm: validationTerm ?? 'Annual',
    },
  });

  useEffect(() => {
    setHasChanged(
      values.approverEmail !== approverEmail || values.validationTerm !== validationTerm
    );
  }, [validationTerm, approverEmail, values.approverEmail, values.validationTerm]);

  return (
    <form onSubmit={handleSubmit} style={{ padding: '0px', margin: '0px', width: '100%' }}>
      <Grid {...licenseBlockManagerStyles.gridContainerManageOffer}>
        <GridItem area='configureOffer' {...licenseBlockManagerStyles.gridItem}>
          <VStack>
            <Text>
              Subscribers requesting access using this promo code will be approved individually
            </Text>
            <VStack spacing='0px'>
              <Text w='100%' justifyContent='leading'>
                Subscription requests sent to this email:
              </Text>
              <TextInput
                id='approverEmail'
                name='approverEmail'
                label=''
                onChange={handleChange}
                value={values.approverEmail}
                isError={!!errors.approverEmail && touched.approverEmail}
                errorMessage={errors.approverEmail}
                style={{ background: 'white' }}
              />
              <RadioTextInput
                id='validationTerm'
                name='validationTerm'
                label='How often do you want to re-validate subscribed users?'
                options={['Monthly', 'Annual']}
                onChange={handleChange}
                value={values.validationTerm}
                isError={!!errors.validationTerm && touched.validationTerm}
                errorMessage={errors.validationTerm}
                style={{ paddingTop: '20px' }}
              />
            </VStack>
          </VStack>
        </GridItem>
        <GridItem area='divider2' bg='#E2E2E0' {...licenseBlockManagerStyles.gridItem} />
        <GridItem area='applyChanges' {...licenseBlockManagerStyles.gridItem}>
          <VStack w='100%' h='100%'>
            <Spacer />
            <HStack w='100%' h='100%'>
              <Spacer />
              <Button
                isLoading={isBusy}
                type='submit'
                isDisabled={!hasChanged || values.approverEmail.length < 6}
                {...licenseBlockManagerStyles.button}
              >
                {approverEmail ? 'Update offering' : 'Create offering'}
              </Button>
              <Spacer />
            </HStack>
            <Spacer />
            {error && <Text {...licenseBlockManagerStyles.errorText}>{error.message}</Text>}
          </VStack>
        </GridItem>
      </Grid>
    </form>
  );
}
