import React, { ReactElement, useEffect } from 'react';

import { Box } from '@chakra-ui/react';

import { useNavigation } from '@modules/core/hooks';
import { IRouteWrapperProps } from './interfaces';

import { routeWrapperStyles } from './styles';

export default function RouteWrapper(props: IRouteWrapperProps): ReactElement {
  const { setRoute } = useNavigation();
  const isRouteWithSideBar = (): boolean => {
    return false;
  };

  useEffect(() => {
    setRoute(props.route);
  }, [props]);

  return <Box {...(isRouteWithSideBar() && routeWrapperStyles.container)}>{props.children}</Box>;
}
