import * as Yup from 'yup';

import { EMAIL_DOMAIN_VALIDATION_ERRORS, EMAIL_VALIDATION_ERRORS } from '@modules/core/constants';

export const emailDomainValidationSchema = Yup.object().shape({
  validationTerm: Yup.string().required(EMAIL_DOMAIN_VALIDATION_ERRORS.required),
  emailDomain: Yup.string()
    .trim()
    .required(EMAIL_DOMAIN_VALIDATION_ERRORS.required)
    .min(5, EMAIL_DOMAIN_VALIDATION_ERRORS.invalidLength)
    .matches(/^[@].[A-Za-z0-9_]+[.].[A-Za-z0-9_]+$/, EMAIL_DOMAIN_VALIDATION_ERRORS.invalid),
});

export const manualApprovalValidationSchema = Yup.object().shape({
  validationTerm: Yup.string().required(EMAIL_DOMAIN_VALIDATION_ERRORS.required),
  approverEmail: Yup.string()
    .trim()
    .required(EMAIL_VALIDATION_ERRORS.required)
    .email(EMAIL_VALIDATION_ERRORS.invalid),
});
