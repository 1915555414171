import React, { useState, ReactElement, useEffect } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { Button, Flex, Box } from '@chakra-ui/react';
import { has } from 'lodash';

import { IQuestionsManagerProps } from './interfaces';
import { useQuery } from '../../../core/hooks';
import { setAnswers as setDynamoDbAnswers } from '../../../core/aws/dynamo-db/affiliate-onboarding/set-answers';
import {
  AnswersType,
  AnswerType,
  QuestionType,
  ISetAnswersInputProps,
} from '../../../core/aws/dynamo-db/affiliate-onboarding/interfaces';

import QuestionManager from '../question-manager';

import { questionsManagerStyles } from './styles';

import { APP_ROUTES } from '../../../core/constants';

function QuestionsManager({ item }: IQuestionsManagerProps): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();
  const questions: QuestionType[] = item.Fields || [];

  const [answers, setAnswers] = useState<AnswersType>({});
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType | null>(null);

  const { isLoading, submit, res } = useQuery<ISetAnswersInputProps, {}>(setDynamoDbAnswers);

  const navigateToDashboard: () => void = () => navigateTo(APP_ROUTES.dashboard);

  const writeAnswer = (key: string, value: AnswerType): void => {
    return setAnswers((oldAnswers) => ({ ...oldAnswers, [key]: value }));
  };

  const isContinueDisallowed = (): boolean => {
    if (!currentQuestion) return true;
    return currentQuestion.isRequired && !has(answers, currentQuestion.FieldName);
  };

  const showNextQuestion = (): void => {
    if (currentQuestion && currentQuestion.Index + 1 <= questions.length) {
      setCurrentQuestion((): QuestionType | null => {
        return questions.find(({ Index }) => Index === currentQuestion.Index + 1) || null;
      });
    }
  };

  const showPrevQuestion = (): void => {
    if (currentQuestion && currentQuestion.Index - 1) {
      setCurrentQuestion((): QuestionType | null => {
        return questions.find(({ Index }) => Index === currentQuestion.Index - 1) || null;
      });
    }
  };

  useEffect((): void => {
    setCurrentQuestion((): QuestionType | null => {
      return questions.find(({ Index }) => Index === 1) || null;
    });
  }, []);

  useEffect((): void => {
    if (res) {
      navigateToDashboard();
    }
  }, [res]);

  return (
    <Flex flexDirection='column' {...questionsManagerStyles.mainContainer}>
      {currentQuestion && (
        <>
          <QuestionManager question={currentQuestion} answers={answers} callback={writeAnswer} />

          <Flex
            {...questionsManagerStyles.buttonBox}
            justifyContent={currentQuestion.Index > 1 ? 'space-between' : 'flex-end'}
          >
            {currentQuestion.Index > 1 && (
              <Button onClick={showPrevQuestion} {...questionsManagerStyles.prevButton}>
                Back
              </Button>
            )}

            <Flex>
              <Box {...questionsManagerStyles.continueButtonBox}>
                {currentQuestion.Index < questions.length ? (
                  <Button
                    isDisabled={isContinueDisallowed()}
                    onClick={showNextQuestion}
                    {...questionsManagerStyles.nextButton}
                  >
                    Continue
                  </Button>
                ) : (
                  <Button
                    isLoading={isLoading}
                    isDisabled={isContinueDisallowed()}
                    onClick={submit.bind(null, { item, answers })}
                    {...questionsManagerStyles.nextButton}
                  >
                    Save all answers
                  </Button>
                )}
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default QuestionsManager;
