import React, { useEffect, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, VStack, Heading, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import { userNameValidationSchema } from './validation-schema';
import { useQuery } from '@modules/core/hooks';
import { TextInput } from '@modules/common-ui/components/text-field';

import { forgotPasswordStyles } from './styles';

import { APP_ROUTES } from '@modules/core/constants';
import { IForgotPasswordRequest, IResetPasswordRequestResult } from './interfaces';
import { getUserFromUserName } from './cognitoUser';

function requestCode(data: IForgotPasswordRequest): Promise<IResetPasswordRequestResult> {
  // console.log('Requesting code...');
  return new Promise((resolve, reject) => {
    const user = getUserFromUserName(data.accountUserName);
    // console.log('Requesting code...');
    user.forgotPassword({
      onSuccess: () => {
        // console.log('onSuccess: ', resData);
        resolve({ success: true } as IResetPasswordRequestResult);
      },
      onFailure: (err) => {
        console.log('error: ', err);
        reject(err);
      },
      inputVerificationCode: (resData) => {
        console.log('Input code: ', resData?.CodeDeliveryDetails);
        resolve({ success: true, ...resData.CodeDeliveryDetails } as IResetPasswordRequestResult);
      },
    });
  });
}

function RequestPasswordResetCode(props: {
  setUserName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setResetRequestResult: React.Dispatch<
    React.SetStateAction<IResetPasswordRequestResult | undefined>
  >;
}): ReactElement {
  const { submit, res, isLoading, err } = useQuery<
    IForgotPasswordRequest,
    IResetPasswordRequestResult
  >(requestCode);
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: submit,
    validationSchema: userNameValidationSchema,
    initialValues: {
      accountUserName: '',
    },
  });

  useEffect((): void => {
    if (res?.success === true) {
      props.setUserName(values.accountUserName);
      props.setResetRequestResult(res);
    }
  }, [res]);

  return (
    <VStack {...forgotPasswordStyles.resetPasswordBox}>
      <Box {...forgotPasswordStyles.container}>
        <form onSubmit={handleSubmit}>
          <VStack spacing='20%'>
            <Heading>Forgot your password</Heading>

            <TextInput
              id='accountUserName'
              name='accountUserName'
              label="Please enter the username associated with your LVED account.  If you don't remember your username, contact support@lved.com"
              onChange={handleChange}
              value={values.accountUserName}
              isError={!!errors.accountUserName && touched.accountUserName}
              errorMessage={errors.accountUserName}
            />

            <VStack spacing='15px' width='100%'>
              <Button isLoading={isLoading} type='submit' {...forgotPasswordStyles.button}>
                Begin password reset
              </Button>

              {err && (
                <Box {...forgotPasswordStyles.textErrorContainer}>
                  <Text {...forgotPasswordStyles.textError}>{err?.message || 'unknown error'}</Text>
                </Box>
              )}
            </VStack>
          </VStack>
        </form>
      </Box>

      <Text {...forgotPasswordStyles.textContainer}>
        Cancel, signin instead? &nbsp;
        <Text {...forgotPasswordStyles.textGreen} as='span'>
          <RouterLink to={APP_ROUTES.signIn}>Login</RouterLink>
        </Text>
      </Text>
    </VStack>
  );
}

export default RequestPasswordResetCode;
