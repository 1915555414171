import React, { ReactElement, useState } from 'react';

import {
  // Text,
  Grid,
  GridItem,
  HStack,
  VStack,
  Button,
  Image,
  Spacer,
} from '@chakra-ui/react';

import { AuthHeaderStyles } from './styles';
import logoImg from '@assets/images/lvedLogoWhite.svg';
import closeMenuImg from '@assets/images/closeMenu.svg';
import hamburgerMenuImg from '@assets/images/hamburgerMenu.svg';
import MenuLink from '../menu-link/MenuLink';
import { useNavigate } from 'react-router';
import { useAuth } from '@app/modules/core/hooks';
import { APP_ROUTES } from '../../../core/constants';

export default function AuthHeader(): ReactElement {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  return (
    <Grid {...AuthHeaderStyles.gridMaster}>
      <GridItem {...AuthHeaderStyles.gridItemVerticalNavigation}>
        {expanded && (
          <VStack {...AuthHeaderStyles.verticalOpenContainer}>
            <HStack spacing='24px' width='100%'>
              <Image
                {...AuthHeaderStyles.hamburgerMenuImage}
                src={closeMenuImg}
                onClick={() => setExpanded(!expanded)}
              />
              <Image {...AuthHeaderStyles.logoImage} src={logoImg} />
              <Spacer />
            </HStack>
            <MenuLink
              title='Home'
              isActiveLink={window.location.pathname.includes(APP_ROUTES.dashboard)}
              url={APP_ROUTES.dashboard}
              isUrlLocal
              closeMenu={() => {
                setExpanded(false);
              }}
            />
            <MenuLink
              title='Profile'
              isActiveLink={window.location.pathname.includes(APP_ROUTES.profile)}
              url={APP_ROUTES.profile}
              isUrlLocal
              closeMenu={() => {
                setExpanded(false);
              }}
            />
            <Spacer />
            <Button
              {...AuthHeaderStyles.button}
              width='100%'
              onClick={() => {
                signOut();
                navigate('/signin');
                setExpanded(false);
              }}
            >
              Sign out
            </Button>
          </VStack>
        )}
        {!expanded && (
          <HStack {...AuthHeaderStyles.verticalClosedContainer}>
            <HStack spacing='24px'>
              <Image
                {...AuthHeaderStyles.hamburgerMenuImage}
                src={hamburgerMenuImg}
                onClick={() => setExpanded(!expanded)}
              />
              <Image boxSize='80px' src={logoImg} />
            </HStack>
            <Spacer />
            <Button
              {...AuthHeaderStyles.button}
              onClick={() => {
                signOut();
                navigate('/signin');
              }}
            >
              Sign out
            </Button>
          </HStack>
        )}
      </GridItem>
      <GridItem {...AuthHeaderStyles.gridItemHorizontalNavigation}>
        <HStack {...AuthHeaderStyles.verticalClosedContainer}>
          <HStack p='10px 0px 10px 0px' spacing='24px'>
            <Image {...AuthHeaderStyles.logoImage} src={logoImg} />
          </HStack>
          <Spacer />
          <MenuLink
            title='Home'
            isActiveLink={window.location.pathname.includes(APP_ROUTES.dashboard)}
            url={APP_ROUTES.dashboard}
            isUrlLocal
          />
          <MenuLink
            title='Profile'
            isActiveLink={window.location.pathname.includes(APP_ROUTES.profile)}
            url={APP_ROUTES.profile}
            isUrlLocal
          />
          <Button
            {...AuthHeaderStyles.button}
            onClick={() => {
              signOut();
              navigate('/signin');
            }}
          >
            Sign out
          </Button>
        </HStack>
      </GridItem>
    </Grid>
  );
}
