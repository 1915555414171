import '@stripe/stripe-js';

import React, { ReactElement, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { Chart, DoughnutController, ArcElement, Title, Tooltip } from 'chart.js';

import AuthContextProvider from '@modules/core/contexts/auth';
import NavContextProvider from '@modules/core/contexts/navigation';
import Router from '@modules/core/routing';
import routes from '@app/routes';
import amplifyConfig from '@modules/core/aws/amplify';

import 'react-toastify/dist/ReactToastify.css';
import InitializeGoogleAnalytics from './modules/core/google-analytics/google-analytics-tracker';
import AppEntryParamsContextProvider from './modules/core/contexts/appEntryParams/AppEntryParamsContextProvider';
import AuthHeader from './modules/common-ui/components/AuthHeader/AuthHeader';
import AuthFooter from './modules/common-ui/components/AuthFooter/AuthFooter';
import { Box, VStack } from '@chakra-ui/react';

Amplify.configure(amplifyConfig);
Chart.register(DoughnutController, ArcElement, Title, Tooltip);

function App(): ReactElement {
  useEffect(() => {
    InitializeGoogleAnalytics();
  });

  return (
    <AuthContextProvider>
      <ToastContainer />
      <NavContextProvider>
        <AppEntryParamsContextProvider entryPointURL={window.location.search}>
          <BrowserRouter>
            <VStack w='100%' bg='#FFFFFF'>
              <AuthHeader />
              <Box h='100px' />
              <Router routes={routes} />
              <Box h='100px' />
              <AuthFooter />
            </VStack>
          </BrowserRouter>
        </AppEntryParamsContextProvider>
      </NavContextProvider>
    </AuthContextProvider>
  );
}

export default App;
