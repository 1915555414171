import React, { useEffect, ReactElement } from 'react';
import { useNavigate, NavigateFunction, Navigate } from 'react-router-dom';

import { Box, Button, Flex, VStack, Heading, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import validationSchema from './validation-schema';
// import { LvedFeatures } from '@modules/common-ui/components/lved-features';
import { TextInput } from '@modules/common-ui/components/text-field';
import { useQuery, useAuth } from '@modules/core/hooks';
import { storageGet, storageClear } from '@modules/core/local-storage';
import { IAuthContext, IConfirmSignupData } from '@modules/core/contexts/auth/interfaces';

import { signUpVerificationStyles } from './styles';

import { APP_ROUTES, LOCAL_STORAGE } from '@modules/core/constants';

function SignupVerification(): ReactElement {
  const email: string | null = storageGet(LOCAL_STORAGE.unverifiedEmail);
  const userName: string | null = storageGet(LOCAL_STORAGE.userName);

  const navigateTo: NavigateFunction = useNavigate();
  const { confirmSignup, resendCode }: IAuthContext = useAuth();

  const {
    submit: resend,
    isLoading: isResendLoad,
    err: resendErr,
  } = useQuery<string, void>(resendCode);
  const {
    submit: verify,
    res: verificationRes,
    isLoading: isVerifyLoad,
    err: verifyErr,
  } = useQuery<IConfirmSignupData, string>(confirmSignup);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: { verificationCode: '' },
    validationSchema,
    onSubmit: () => {
      if (userName) {
        verify({ userName, verificationCode: values.verificationCode });
      }
    },
  });

  const resendVerificationCode = () => {
    if (email) {
      resend(email);
    }
  };

  const obscureEmail = (emailAddress: string): string => {
    const [name, domain] = emailAddress.split('@');
    return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
  };

  useEffect((): void => {
    if (verificationRes) {
      storageClear();
      navigateTo(APP_ROUTES.signIn);
    }
  }, [verificationRes]);

  if (!email) return <Navigate replace to={APP_ROUTES.signUp} />;

  return (
    <Flex {...signUpVerificationStyles.container}>
      <Box {...signUpVerificationStyles.verificationBox}>
        <form onSubmit={handleSubmit}>
          <VStack {...signUpVerificationStyles.contentBox}>
            <Heading>Email Verification</Heading>

            <Text {...signUpVerificationStyles.textWrapper}>
              We have sent the verification code to {obscureEmail(email)}. Please check your mail
              box
            </Text>

            <TextInput
              id='verificationCode'
              name='verificationCode'
              label='Verification Code'
              onChange={handleChange}
              value={values.verificationCode}
              isError={!!errors.verificationCode && touched.verificationCode}
              errorMessage={errors.verificationCode}
              style={{ ...signUpVerificationStyles.verificationCodeInput }}
            />

            <Button
              isLoading={isVerifyLoad}
              type='submit'
              {...signUpVerificationStyles.verifyButton}
            >
              Verify email, return to Login
            </Button>

            <Button
              isLoading={isResendLoad}
              onClick={resendVerificationCode}
              {...signUpVerificationStyles.resendButton}
            >
              click here to resend the code
            </Button>

            {verifyErr && (
              <Box {...signUpVerificationStyles.textContainer}>
                <Text {...signUpVerificationStyles.textError}>
                  {verifyErr?.message || 'unknown error'}
                </Text>
              </Box>
            )}

            {resendErr && (
              <Box {...signUpVerificationStyles.textContainer}>
                <Text {...signUpVerificationStyles.textError}>
                  {resendErr?.message || 'unknown error'}
                </Text>
              </Box>
            )}
          </VStack>
        </form>
      </Box>
    </Flex>
  );
}

export default SignupVerification;
