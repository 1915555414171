import * as Yup from 'yup';

import {
  USERNAME_VALIDATION_ERRORS,
  EMAILED_CODE_VALIDATION_ERRORS,
  PASSWORD_VALIDATION_ERRORS,
} from '../../modules/core/constants/validation';

export const userNameValidationSchema = Yup.object().shape({
  accountUserName: Yup.string()
    .trim()
    .required(USERNAME_VALIDATION_ERRORS.required)
    .min(6, USERNAME_VALIDATION_ERRORS.invalidLength)
    .matches(/^[A-Za-z0-9]+$/, USERNAME_VALIDATION_ERRORS.invalidCharacters),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  emailedCode: Yup.string()
    .trim()
    .required(EMAILED_CODE_VALIDATION_ERRORS.required)
    .matches(/^[0-9]{6}$/, EMAILED_CODE_VALIDATION_ERRORS.invalid),

  newPassword: Yup.string()
    .required(PASSWORD_VALIDATION_ERRORS.required)
    .min(7, PASSWORD_VALIDATION_ERRORS.min)
    .matches(/[0-9]/, PASSWORD_VALIDATION_ERRORS.oneNumber)
    .matches(/[a-z]/, PASSWORD_VALIDATION_ERRORS.oneLowerCase)
    .matches(/[A-Z]/, PASSWORD_VALIDATION_ERRORS.oneUpperCase)
    .matches(/[^\w]/, PASSWORD_VALIDATION_ERRORS.oneSymbol),

  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword')],
    PASSWORD_VALIDATION_ERRORS.matching
  ),
});
