import React, { ReactElement } from 'react';
import { Routes } from 'react-router-dom';

import { IRouterProps } from './interfaces';
import { createRoutes } from './create-routes';

function Router({ routes }: IRouterProps): ReactElement {
  return (
    <div>
      <Routes>{createRoutes(routes)}</Routes>
    </div>
  );
}

export default Router;
