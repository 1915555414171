import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IActivateOfferRes,
  IActivateEmailDomainVerifiedOfferReq,
  IActivateManualVerifiedOfferReq,
} from '@modules/core/aws/lambdas/activate-offer/interfaces';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function activateOffer(discountPts: number): Promise<IActivateOfferRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IActivateOfferRes>(credentials, {
    FunctionName: AWS_LAMBDAS.activateOffer,
    Payload: JSON.stringify({ discountPts }),
  });
}

export async function activateEmailDomainVerifiedOffer(
  params: IActivateEmailDomainVerifiedOfferReq
): Promise<IActivateOfferRes> {
  const credentials: ICredentials = await Auth.currentCredentials();
  return lambdaGeneralCaller<IActivateOfferRes>(credentials, {
    FunctionName: AWS_LAMBDAS.activateOffer,
    Payload: JSON.stringify({
      subscriberValidationModel: 'email domain',
      validationTerm: params.validationTerm,
      emailDomain: params.emailDomain,
    }),
  });
}

export async function activateManualVerifiedOffer(
  params: IActivateManualVerifiedOfferReq
): Promise<IActivateOfferRes> {
  const credentials: ICredentials = await Auth.currentCredentials();
  return lambdaGeneralCaller<IActivateOfferRes>(credentials, {
    FunctionName: AWS_LAMBDAS.activateOffer,
    Payload: JSON.stringify({
      subscriberValidationModel: 'manual approval',
      validationTerm: params.validationTerm,
      approverEmail: params.approverEmail,
    }),
  });
}
