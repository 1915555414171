export enum AWS_LAMBDAS {
  linkAffiliate = 'prod_linkAffiliateToIdentityId',
  affiliateDashboard = 'prod_getAffiliateDashboardMetrics',
  manageFamilyPlan = 'prod_manageAffiliateFamilyPlan',
  syncProfile = 'prod_syncAcciliateCognitoContactInfo',
  activateOffer = 'prod_activateAffiliateOffer',
  getPromoContent = 'prod_createAffiliatePromoContent',
}

export enum AWS_LAMBDAS_OPERATIONS {
  inviteFamilyMember = 'inviteFamilyMember',
  getFamilyMemberSlotStatus = 'getFamilyMemberSlotStatus',
}

export enum AWS_LAMBDA_ERRORS {
  noData = 'No data returned',
  unknownFormat = 'Some problems with the data received: unknown format',
}
