export enum APP_ROUTES {
  signIn = '/signin',
  signUp = '/signup',
  signUpVerification = '/signup-verification',
  resetPassword = '/resetPassword',
  dashboard = '/dashboard',
  onboarding = '/myAffiliateOnboarding',
  welcome = '/welcome',
  payment = '/payment',
  paymentCompletion = '/payment-completion',
  profile = '/settingsAccountProfile',
  root = '/',
  isNotExist = '*',
  leaveCurrentPage = '#',
}

export const WITHOUT_SIDEBAR_ROUTES = [
  APP_ROUTES.signIn,
  APP_ROUTES.signUp,
  APP_ROUTES.signUpVerification,
  APP_ROUTES.payment,
  APP_ROUTES.paymentCompletion,
  APP_ROUTES.resetPassword,
];

export const WITHOUT_TIMER_ROUTES = [
  APP_ROUTES.signIn,
  APP_ROUTES.signUp,
  APP_ROUTES.signUpVerification,
  APP_ROUTES.payment,
  APP_ROUTES.paymentCompletion,
  APP_ROUTES.resetPassword,
];
