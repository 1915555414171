export const sectionStyles = {
  heading: {
    alignSelf: 'flex-start',
    fontSize: 'xl',
  },

  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    color: 'inherit',
  },

  contentBox: {
    width: '100%',
    height: '100%',
    minH: '140px',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '50px',
    spacing: '5',
  },
} as const;
