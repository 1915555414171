import React, { useEffect, ReactElement } from 'react';
import { Link as RouterLink, useNavigate, NavigateFunction } from 'react-router-dom';

import { ISignUpResult } from 'amazon-cognito-identity-js';
import {
  Box,
  Button,
  Flex,
  VStack,
  Heading,
  InputGroup,
  Text,
  Link,
  Image,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import validationSchema from './validation-schema';
import { useQuery, useAuth, useAppEntryParams } from '@modules/core/hooks';
import { srorageSet } from '@modules/core/local-storage';
// import { LvedFeatures } from '@modules/common-ui/components/lved-features';
import logoImg from '@assets/images/lvedLogo.svg';
import { CheckBox } from '@modules/common-ui/components/check-box';
import { PhoneInput, PasswordInput, TextInput } from '@modules/common-ui/components/text-field';
import {
  IUserRegistrationData as IUserData,
  IAuthContext,
} from '@modules/core/contexts/auth/interfaces';

import { signUpStyles } from './styles';

import { LVED_LINKS, APP_ROUTES, LOCAL_STORAGE } from '@modules/core/constants';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import UserCategory, {
  SignUpCompletedAction,
} from '@app/modules/core/google-analytics/events/signupGAEvent';

function SignUp(): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();
  const appEntryParams = useAppEntryParams();
  const { signUp }: IAuthContext = useAuth();
  const { submit, res, isLoading, err } = useQuery<IUserData, ISignUpResult>(signUp);
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: () => {
      submit({ ...values, referrer: appEntryParams.referrer });
    },
    validationSchema,
    initialValues: {
      companyName: '',
      userName: '',
      phoneNumber: '',
      email: '',
      password: '',
      passwordCheck: '',
      acceptedTerms: false,
      acceptedSMS: false,
    },
  });

  useEffect((): void => {
    if (res?.user) {
      TrackGoogleAnalyticsEvent(
        UserCategory,
        SignUpCompletedAction,
        `Affiliate: ${values.companyName}`
      );
      srorageSet(LOCAL_STORAGE.unverifiedEmail, values.email);
      srorageSet(LOCAL_STORAGE.userName, values.userName);
      navigateTo(APP_ROUTES.signUpVerification);
    }
  }, [res]);

  return (
    <Flex {...signUpStyles.container}>
      <Box {...signUpStyles.signUpBox}>
        <form onSubmit={handleSubmit}>
          <VStack {...signUpStyles.contentBox}>
            <Box display='flex' flexDirection='row' width='100%' justifyContent='center'>
              <Image {...signUpStyles.logo} src={logoImg} />
            </Box>
            <Heading>Create an affiliate account</Heading>
            <Text {...signUpStyles.textContainer}>
              Already have an account? &nbsp;
              <Text {...signUpStyles.textGreen} as='span'>
                <RouterLink to={APP_ROUTES.signIn}>Login</RouterLink>
              </Text>
            </Text>

            <InputGroup {...signUpStyles.inputGroup}>
              <TextInput
                id='companyName'
                name='companyName'
                label='Entity Name'
                onChange={handleChange}
                value={values.companyName}
                isError={!!errors.companyName && touched.companyName}
                errorMessage={errors.companyName}
                style={{ ...signUpStyles.firstNameinput }}
              />
              <TextInput
                id='userName'
                name='userName'
                label='User Name for Login'
                onChange={handleChange}
                value={values.userName}
                isError={!!errors.userName && touched.userName}
                errorMessage={errors.userName}
              />
            </InputGroup>

            <TextInput
              id='email'
              name='email'
              type='email'
              label='Email Address'
              onChange={handleChange}
              value={values.email}
              isError={!!errors.email && touched.email}
              errorMessage={errors.email}
            />

            <PhoneInput
              isError={!!errors.phoneNumber && touched.phoneNumber}
              errorMessage={errors.phoneNumber}
              label='Mobile Number'
              id='phoneNumber'
              name='phoneNumber'
              value={values.phoneNumber}
              onChange={handleChange}
            />

            <PasswordInput
              id='password'
              name='password'
              label='Password'
              onChange={handleChange}
              value={values.password}
              isError={!!errors.password && touched.password}
              errorMessage={errors.password}
            />

            <PasswordInput
              id='passwordCheck'
              name='passwordCheck'
              label='Re-enter Password to verify'
              onChange={handleChange}
              value={values.passwordCheck}
              isError={!!errors.passwordCheck && touched.passwordCheck}
              errorMessage={errors.passwordCheck}
            />

            <CheckBox
              id='acceptedTerms'
              name='acceptedTerms'
              onChange={handleChange}
              checked={values.acceptedTerms}
              isError={!!errors.acceptedTerms && touched.acceptedTerms}
              errorMessage={errors.acceptedTerms}
            >
              I agree to LVED&apos;s &nbsp;
              <Link {...signUpStyles.textGreen} href={LVED_LINKS.termsOfService} isExternal>
                Terms of Service &nbsp;
              </Link>
            </CheckBox>

            <CheckBox
              id='acceptedSMS'
              name='acceptedSMS'
              onChange={handleChange}
              checked={values.acceptedSMS}
              isError={!!errors.acceptedSMS && touched.acceptedSMS}
              errorMessage={errors.acceptedSMS}
            >
              I accept receiving SMS messages from LVED to secure my account using Multi-factor
              Authentication
            </CheckBox>

            <Button isLoading={isLoading} type='submit' {...signUpStyles.button}>
              Create account
            </Button>

            {err && (
              <Box {...signUpStyles.textErrorContainer}>
                <Text {...signUpStyles.textError}>{err?.message || 'unknown error'}</Text>
              </Box>
            )}
          </VStack>
        </form>
      </Box>
    </Flex>
  );
}

export default SignUp;
