export const dashboardStyles = {
  gridContainer: {
    templateAreas: {
      base: `"entityDetails"
             "promotion"
             "pricing"
             "stats"`,
      lg: `"entityDetails promotion"
           "pricing stats"`,
    },
    // gridTemplateRows: { base: '150px, 330px, 210px, 875px', lg: '130px 320px' },
    gridTemplateColumns: { base: '395px', lg: '500px auto' },
    gap: { base: '15px', sm: '30px', lg: '50px' },
    color: 'blackAlpha.700',
    fontWeight: 'bold',
    width: { base: '100%', lg: '100%' },
    margin: '2.5px auto',
  },

  gridContainerForBulkLicenses: {
    templateAreas: {
      base: `"title"
             "entityDetails"
             "promotion"
             "stats"
             "verificationModel"`,
      lg: `"title promotion"
           "entityDetails stats"
           "verificationModel verificationModel"`,
    },
    gridTemplateColumns: { base: '395px', lg: '500px 600px' },
    gap: { base: '15px', sm: '30px', lg: '50px' },
    color: 'blackAlpha.700',
    fontWeight: 'bold',
    width: { base: '100%', lg: '100%' },
    margin: '2.5px auto',
  },

  gridContainerWBequestSummary: {
    templateAreas: {
      base: `"entityDetails"
             "bequestSummary"
             "promotion"
             "pricing"
             "stats"`,
      lg: `"entityDetails promotion"
           "pricing stats"
           "bequestSummary bequestSummary"`,
    },
    // gridTemplateRows: { base: '150px 330px 150px 875px 210px', lg: '130px 320px 100px' },
    gridTemplateColumns: { base: '395px', lg: '500px auto' },
    gap: { base: '15px', sm: '30px', lg: '50px' },
    color: 'blackAlpha.700',
    fontWeight: 'bold',
    width: { base: '100%', lg: '100%' },
    margin: '2.5px auto',
  },

  gridItemBox: {
    backgroundColor: '#FEFEFE',
    borderRadius: '0px',
    padding: { base: '10px', md: '20px' },
    width: '100%',
    height: '100%',
  },

  headingText: {
    width: '100%',
    justifyContent: 'left',
    fontSize: '36px',
    fontFamily: 'Lato',
    fontWeight: '900',
    lineHeight: '54px',
    letterSpacing: '0px',
    color: '#000000',
  },

  spinnerBox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'brand.green.100',
    size: 'xl',
  },

  latestText: {
    font: 'normal normal normal 12px/18px Lato',
    fontWeight: '400',
  },

  updatesContentBox: {
    spacing: '2',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    alignItems: 'normal',
  },

  tasksContentBox: {
    height: '100%',
  },

  scoreContentBox: {
    spacing: '2',
  },

  widgetHeader: {
    size: 'md',
  },

  score: {
    size: '3xl',
    color: '#FDE000',
  },
};
