import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Grid, GridItem, Text, Spinner, VStack } from '@chakra-ui/react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { useAuth, useQuery } from '@modules/core/hooks';
import { IAffiliateDashboardMetricsRes } from '@modules/core/aws/lambdas/dashboard/interfaces';

import { dashboardStyles } from './styles';
import { IAuthContext } from '@app/modules/core/contexts/auth/interfaces';
import { APP_ROUTES } from '@app/modules/core/constants';
import {
  BequestSummary,
  PartnershipMetrics,
  PricingControl,
  ProfileSummary,
  PromoContent,
} from '@app/modules/common-ui/components/revenue-share-management';
import { getDashboardMetrics } from '@app/modules/core/aws/lambdas/dashboard';
import LicenseBlockManager from '@app/modules/common-ui/components/license-block-management/LicenseBlockManager';
import { BlockLicenseStats } from '@app/modules/common-ui/components/license-block-management/BlockLicenseStats';

function Dashboard(): ReactElement {
  const {
    submit: getMetrics,
    res: metrics,
    isLoading: isMetricsLoading,
    err: error,
  } = useQuery<void, IAffiliateDashboardMetricsRes>(getDashboardMetrics);

  const { user, reloadUser }: IAuthContext = useAuth();
  const navigateTo: NavigateFunction = useNavigate();
  const [updatingMetrics, setUpdatingMetrics] = useState<boolean>(false);

  function onOfferUpdate() {
    setUpdatingMetrics(true);
    getMetrics();
  }

  useEffect(() => {
    setUpdatingMetrics(false);
  }, [metrics]);

  useEffect(() => {
    Promise.all([getMetrics()])
      .catch(() => {})
      .finally(() => {});
    reloadUser();
  }, []);

  useEffect(() => {
    console.log('Redirecting from dashboard to welcome');
    if (user?.needsOnboarding) navigateTo(APP_ROUTES.welcome);
  }, [user]);

  if (!user || updatingMetrics) {
    return <Spinner />;
  }
  return (
    <VStack gap='56px' margin='20px' width='100%'>
      {metrics?.subscriptionModel === 'Bulk License' && metrics.licenseBlockData && (
        <Grid {...dashboardStyles.gridContainerForBulkLicenses}>
          <GridItem area='title' {...dashboardStyles.headingText}>
            Sponsored Plan
          </GridItem>

          <GridItem area='promotion'>
            {metrics?.currentOffer && (
              <PromoContent promoCode={metrics.currentOffer.promoCode ?? '********'} />
            )}
          </GridItem>

          <GridItem area='entityDetails'>{user && <ProfileSummary user={user} />}</GridItem>

          <GridItem area='stats'>
            <BlockLicenseStats
              subscribers={metrics.subscribers}
              licensedSeats={metrics.licenseBlockData}
            />
          </GridItem>

          <GridItem area='verificationModel'>
            <LicenseBlockManager
              currentOffer={metrics.currentOffer}
              onOfferUpdate={onOfferUpdate}
            />
          </GridItem>
        </Grid>
      )}

      {(metrics?.subscriptionModel !== 'Bulk License' || !metrics.licenseBlockData) && (
        <>
          <Text {...dashboardStyles.headingText}>Affiliate Program</Text>
          <Grid
            {...(metrics?.bequests
              ? dashboardStyles.gridContainerWBequestSummary
              : dashboardStyles.gridContainer)}
          >
            <GridItem area='entityDetails'>{user && <ProfileSummary user={user} />}</GridItem>

            <GridItem area='promotion'>
              {metrics?.currentOffer && (
                <PromoContent
                  affiliateUsername={
                    user.subscriptionModel === 'Bulk License' ? undefined : user.userName
                  }
                  promoCode={metrics.currentOffer.promoCode ?? '********'}
                />
              )}
            </GridItem>

            <GridItem area='pricing'>
              {metrics?.basePricing && metrics?.programTierData && (
                <PricingControl
                  basePricing={metrics.basePricing}
                  affiliateDiscount={metrics.affiliateDiscount}
                  currentPricingDiscountPts={metrics.currentOffer?.currentPricingDiscountPts ?? 0}
                  maxDiscountPts={metrics.programTierData.maxDiscountPts ?? 0}
                  onOfferUpdate={onOfferUpdate}
                  promoCode={metrics.currentOffer?.promoCode}
                />
              )}
            </GridItem>

            <GridItem area='stats'>
              <Box>
                {isMetricsLoading && <Spinner />}
                {metrics?.funds && metrics.programTierData && (
                  <PartnershipMetrics
                    tierData={metrics.programTierData}
                    subscribers={metrics.subscribers}
                    funds={metrics.funds}
                  />
                )}
              </Box>
            </GridItem>
            {metrics?.bequests && (
              <GridItem area='bequestSummary'>
                <BequestSummary bequestData={metrics.bequests} />
              </GridItem>
            )}
          </Grid>
        </>
      )}

      {error && (
        <Text w='80%' margin='2.5px auto'>
          {error.message}
        </Text>
      )}
    </VStack>
  );
}

export default Dashboard;
