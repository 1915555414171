const UserCategory: string = 'User';
const SignUpCompletedAction: string = 'sign_up';
const SubscribedCompletedAction: string = 'purchase';
const JoinedGroupPlanCompletedAction: string = 'join_group';

export default UserCategory;
export {
  UserCategory,
  SignUpCompletedAction,
  SubscribedCompletedAction,
  JoinedGroupPlanCompletedAction,
};
