export const onboardingStyles = {
  container: {
    maxWidth: { base: '350px', lg: '650px' },
    margin: { base: '10px auto', lg: '40px auto' },
    alignItems: 'center',
  },

  questionsBox: {
    alignItems: 'center',
    width: 'full',
  },

  questionsContent: {
    spacing: '2',
    width: 'full',
  },

  welcomeTextBox: {
    spacing: '2',
  },

  welcomeAdditionalText: {
    fontSize: 'xl',
  },

  getStartedBox: {
    marginTop: '80px',
    alignItems: 'center',
  },

  getStartedButton: {
    marginTop: '18px',
    width: '221px',
    height: '56px',
    backgroundColor: 'brand.green.100',
    color: 'white',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },
};
