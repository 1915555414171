export const AuthFooterStyles = {
  gridMaster: {
    templateColumns: 'repeat(5, 1fr)',
    w: '100%',
    bg: '#080809',
  },

  gridAlert: {
    colSpan: 5,
  },

  gridItemDouble: {
    colSpan: { base: 5, md: 2 },
    p: { base: '48px', md: '24px' },
    display: 'flex',
  },

  gridItemSingle: {
    colSpan: { base: 5, md: 1 },
    p: { base: '48px', md: '24px' },
    display: 'flex',
  },

  logo: {
    w: '162px',
  },

  socialLogosContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '24px',
  },

  socialLogoContainer: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '1000px',
    border: '1px solid var(--clickable-white, #FFF)',
  },

  socialLogo: {
    width: '20px',
    height: '20px',
    flexShrink: '0',
  },

  aboutLvedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
  },

  description: {
    alignSelf: 'stretch',
    color: 'var(--typography-placeholder, #BABCBF)',
    fontFamily: 'Lato',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
  },

  pageLinksParentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
  },

  sectionTitle: {
    color: 'var(--typography-white, #FFF)',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
  },

  pageLinksChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
  },

  pageLink: {
    color: 'var(--clickable-white, #FFF)',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  },

  newsLetterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
  },

  button: {
    bg: 'var(--clickable-white, #FFF)',
    display: 'flex',
    width: { base: '300px', sm: '131px' },
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    borderRadius: '1000px',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },

  textInputContainer: {
    display: 'flex',
    padding: '0px 0px 0px 10px',
    width: '300px',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid var(--elements-selected, #DDDDDF)',
    background: 'var(--input-default, #FFF)',
    color: 'var(--typography-placeholder, #080809)',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
  },
} as const;
