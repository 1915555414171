import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IGetPromoContentRes,
} from '@modules/core/aws/lambdas/get-promo-content/interfaces';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function getPromoContent(): Promise<IGetPromoContentRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IGetPromoContentRes>(credentials, {
    FunctionName: AWS_LAMBDAS.getPromoContent,
    Payload: JSON.stringify({}),
  });
}
