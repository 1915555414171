import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash';

import { UserProfileisNotExistError } from '@modules/core/errors';
import { EntityProfileType, IDynamoDbGetParams as IParams, ICredentials } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function getUserProfile(): Promise<EntityProfileType> {
  const credentials: ICredentials = await Auth.currentCredentials();
  // console.log(`Using credentials to get user profile: ${credentials.identityId}`);
  const params: IParams = {
    TableName: DYNAMO_DB_TABLES.affiliateObjects,
    Key: { affiliateId: credentials.identityId },
  };

  const clientDB: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  return new Promise<EntityProfileType>((resolve, reject): void => {
    // console.log(params);
    clientDB.get(params, (err: AWSError, data: DynamoDB.DocumentClient.GetItemOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      if (!data.Item || isEmpty(data.Item)) {
        return reject(new UserProfileisNotExistError());
      }

      return resolve(data.Item as EntityProfileType);
    });
  });
}
