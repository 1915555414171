export const avatarStyles = {
  avatarIconBox: {
    justify: 'space-between',
    width: '100%',
    alignItems: 'flex-end',
  },

  avatarContainer: {
    spacing: '10',
  },

  avatar: {
    width: '152px',
    height: '152px',
    // borderRadius: '100%',
    objectFit: 'contain',
  },
} as const;

export const profileSummaryStyles = {
  entityName: {
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  entityDetails: {
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
  },

  entityDetailsClickable: {
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#045f60',
  },
};

export const profileBasicInfoStyles = {
  container: {
    // height: { base: '1500px', sm: '700px' },
    width: '100%',
  },

  contentBox: {
    spacing: '10',
    width: '100%',
    marginTop: '50px !important',
  },

  avatarIconBox: {
    justify: 'space-between',
    width: '100%',
    alignItems: 'flex-end',
  },

  avatarContainer: {
    spacing: '10',
  },

  editIcon: {
    boxSize: '7',
    color: 'brand.green.100',
    cursor: 'pointer',
  },

  separator: {
    width: '100%',
    height: '2px',
    backgroundColor: 'gray.200',
  },

  userInfoContainer: {
    width: '100%',
    spacing: '10',
    color: 'brand.gray.100',
  },

  userInfoColumn: {
    width: '100%',
    justifyContent: 'space-between',
    spacing: '40',
    alignItems: 'flex-start',
  },

  userInfoSubContainer: {
    justifyContent: 'leading',
    width: '100%',
    spacing: '20',
    alignItems: 'flex-start',
  },

  userInfoSubSection: {
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'space-between',
  },

  greenText: {
    width: '60%',
    color: 'brand.green.100',
    justifyContent: 'end',
    textAlign: 'end',
  },

  fieldName: {
    fontSize: 'md',
    width: '150px',
  },

  avatar: {
    width: '152px',
    height: '152px',
    // borderRadius: '100%',
    objectFit: 'contain',
  },
} as const;
