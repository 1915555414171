import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, ISetAnswersInputProps } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function setAnswers({ answers }: ISetAnswersInputProps): Promise<{}> {
  const credentials: ICredentials = await Auth.currentCredentials();

  const dynamoClient: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.affiliateObjects,
    Key: { affiliateId: credentials.identityId },
    UpdateExpression: 'set pendingUpdates = :pendingUpdates remove needsOnboarding',
    ExpressionAttributeValues: {
      ':pendingUpdates': {
        'Last Update Timestamp': new Date().toISOString(),
        ...answers,
      },
    },
    ReturnValues: 'ALL_NEW',
  };

  return new Promise<{}>((resolve, reject): void => {
    dynamoClient.update(
      params,
      (err: AWSError, data: DynamoDB.DocumentClient.UpdateItemOutput): void => {
        if (err) {
          return reject(new Error(err.message || JSON.stringify(err)));
        }
        return resolve(data);
      }
    );
  });
}
