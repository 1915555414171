export enum PASSWORD_VALIDATION_ERRORS {
  required = 'Password is required',
  min = 'Password must be 7 symbols or more',
  oneNumber = 'Password requires at least one number',
  oneLowerCase = 'Password requires at least one lowercase letter',
  oneUpperCase = 'Password requires at least one uppercase letter',
  oneSymbol = 'Password requires at least one special symbol',
  checkRequired = 'You must enter your password a second time to verify',
  matching = 'Passwords must match',
}

export enum EMAIL_DOMAIN_VALIDATION_ERRORS {
  required = 'You must indicate the domain where validation will be sent',
  invalid = 'Invalid email domain (e.g. "@yourDomain.com")',
  invalidLength = 'Email domain must be at least 5 characters',
  invalidCharacters = 'You have used characters that are not valid for an email domain',
}

export enum EMAIL_VALIDATION_ERRORS {
  required = 'Email is required',
  invalid = 'Invalid email address',
}

export enum EMAILED_CODE_VALIDATION_ERRORS {
  required = 'Verification code is required',
  invalid = 'Invalid verification code, expected 6 digits.',
}

export enum MFA_VALIDATION_ERRORS {
  required = 'A 6-digit MFA code is required to proceed',
  invalid = 'Invalid MFA code',
}

export enum USERNAME_VALIDATION_ERRORS {
  required = 'You muyst specify the username associated with this affiliate',
  invalidLength = 'Invalid username, expected at least 6 digits',
  invalidCharacters = 'Invalid username, it cannot contain spaces',
}

export enum PHONE_VALIDATION_ERRORS {
  required = 'Phone number is required',
  invalid = 'The data entered does not match the phone number template',
}

export enum FIRST_NAME_VALIDATION_ERRORS {
  required = 'First name is required',
  invalid = 'This field can only contain symbols that are common for names',
}

export enum LAST_NAME_VALIDATION_ERRORS {
  required = 'Last name is required',
  invalid = 'This field can only contain symbols that are common for names',
}

export enum ACCEPT_TERMS_VALIDATION_ERRORS {
  required = 'Required',
  invalid = 'You must accept the terms and conditions',
}

export enum ACCEPT_SMS_VALIDATION_ERRORS {
  required = 'Required',
  invalid = 'You must accept authentication via SMS',
}

export enum NAME_VALIDATION_ERRORS {
  required = 'Your full name is required',
  invalid = 'First and last name should be provided',
}
