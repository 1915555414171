import React, { ReactElement, useEffect, useState } from 'react';

import { Button, Grid, GridItem, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
// import { offerDataType } from '@app/modules/core/aws/lambdas/dashboard/interfaces';
import { useQuery } from '@app/modules/core/hooks';
import {
  IActivateEmailDomainVerifiedOfferReq,
  IActivateOfferRes,
} from '@app/modules/core/aws/lambdas/activate-offer/interfaces';
import { activateEmailDomainVerifiedOffer } from '@app/modules/core/aws/lambdas/activate-offer';
import { useFormik } from 'formik';
import { licenseBlockManagerStyles } from './styles';
import { TextInput } from '../text-field';
import RadioTextInput from '../text-field/RadioTextInput';
import { validationTermType } from '@app/modules/core/aws/lambdas/dashboard/interfaces';
import { emailDomainValidationSchema } from './validation-schema';

export function SubscriberValidationEmail({
  validationTerm,
  validationEmailDomain,
  onOfferUpdate,
}: {
  validationTerm?: validationTermType;
  validationEmailDomain?: string;
  onOfferUpdate?: () => void;
}): ReactElement {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const {
    submit: handleActivateOffer,
    // res: updatedPromo,
    isLoading: isBusy,
    err: error,
  } = useQuery<IActivateEmailDomainVerifiedOfferReq, IActivateOfferRes>(
    activateEmailDomainVerifiedOffer
  );

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: (data: IActivateEmailDomainVerifiedOfferReq) => {
      return handleActivateOffer(data).then(onOfferUpdate);
    },
    validationSchema: emailDomainValidationSchema,
    initialValues: {
      emailDomain: validationEmailDomain ?? '@',
      validationTerm: validationTerm ?? 'Annual',
    },
  });

  useEffect(() => {
    setHasChanged(
      values.emailDomain !== validationEmailDomain || values.validationTerm !== validationTerm
    );
  }, [validationTerm, validationEmailDomain, values.emailDomain, values.validationTerm]);

  return (
    <form onSubmit={handleSubmit} style={{ padding: '0px', margin: '0px', width: '100%' }}>
      <Grid {...licenseBlockManagerStyles.gridContainerManageOffer}>
        <GridItem area='configureOffer' {...licenseBlockManagerStyles.gridItem}>
          <VStack>
            <Text>
              {validationEmailDomain
                ? `Users who can validate an email address in the domain: ${validationEmailDomain} may subscribe`
                : `Allow anyone with an email account in your domain subscribe to LVED`}
            </Text>
            <VStack spacing='0px'>
              <Text w='100%' justifyContent='leading'>
                Your email domain (e.g. @lved.com):
              </Text>
              <TextInput
                id='emailDomain'
                name='emailDomain'
                label=''
                onChange={handleChange}
                value={values.emailDomain}
                isError={!!errors.emailDomain && touched.emailDomain}
                errorMessage={errors.emailDomain}
                style={{ background: 'white' }}
              />
              <RadioTextInput
                id='validationTerm'
                name='validationTerm'
                label='How often do you want subscribers to validate access to email in your domain?'
                options={['Monthly', 'Annual']}
                onChange={handleChange}
                value={values.validationTerm}
                isError={!!errors.validationTerm && touched.validationTerm}
                errorMessage={errors.validationTerm}
                style={{ paddingTop: '20px' }}
              />
            </VStack>
          </VStack>
        </GridItem>
        <GridItem area='divider2' bg='#E2E2E0' {...licenseBlockManagerStyles.gridItem} />
        <GridItem area='applyChanges' {...licenseBlockManagerStyles.gridItem}>
          <VStack w='100%' h='100%'>
            <Spacer />
            <HStack w='100%' h='100%'>
              <Spacer />
              <Button
                isLoading={isBusy}
                type='submit'
                isDisabled={!hasChanged || values.emailDomain.length < 4}
                {...licenseBlockManagerStyles.button}
              >
                {validationEmailDomain ? 'Update offering' : 'Create offering'}
              </Button>
              <Spacer />
            </HStack>
            <Spacer />
          </VStack>
          {error && <Text {...licenseBlockManagerStyles.errorText}>{error.message}</Text>}
        </GridItem>
      </Grid>
    </form>
  );
}
