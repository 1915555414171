import React, { ReactElement, useEffect, useState } from 'react';

import { ILicenseBlockManager } from './interfaces';
import { Grid, GridItem, Radio, RadioGroup, Stack, Text, VStack } from '@chakra-ui/react';
import { SubscriberValidationEmail } from './SubscriberValidationEmailDomain';
import { ApproverEmail } from '.';
import { subscriberValidationModel } from '@app/modules/core/aws/lambdas/dashboard/interfaces';
import { licenseBlockManagerStyles } from './styles';

export default function LicenseBlockManager({
  currentOffer,
  onOfferUpdate,
}: ILicenseBlockManager): ReactElement {
  const [selectedOfferType, setSelectedOfferType] =
    useState<subscriberValidationModel>('email domain');
  useEffect(() => {
    setSelectedOfferType(currentOffer?.subscriberValidationModel ?? 'email domain');
  }, [currentOffer]);

  return (
    <Grid {...licenseBlockManagerStyles.gridContainer}>
      <GridItem area='selection' {...licenseBlockManagerStyles.gridItem}>
        <VStack padding='10px'>
          <Text>How do you want to validate subscribers?</Text>
          <RadioGroup
            onChange={(val) => {
              setSelectedOfferType(val as subscriberValidationModel);
            }}
            value={selectedOfferType}
          >
            <Stack direction='column'>
              <Radio value='email domain'>User validates their email address</Radio>
              <Radio value='manual approval'>Manually approve each subscriber</Radio>
              <Radio value='PII challenge'>Upload a PII challenge database</Radio>
            </Stack>
          </RadioGroup>
        </VStack>
      </GridItem>
      <GridItem area='divider1' bg='#E2E2E0' />
      <GridItem area='details' {...licenseBlockManagerStyles.gridItem}>
        <VStack w='100%' padding='0px' margin='0px'>
          {selectedOfferType === 'email domain' && (
            <SubscriberValidationEmail onOfferUpdate={onOfferUpdate} {...currentOffer} />
          )}
          {selectedOfferType === 'manual approval' && (
            <ApproverEmail onOfferUpdate={onOfferUpdate} {...currentOffer} />
          )}
          {selectedOfferType === 'PII challenge' && <Text>Not yet supported</Text>}
        </VStack>
      </GridItem>
    </Grid>
  );
}
