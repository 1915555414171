import React, { ReactElement, useEffect } from 'react';

import {
  Spacer,
  HStack,
  Text,
  GridItem,
  Image,
  Button,
  Tooltip,
  Grid,
  // VStack,
} from '@chakra-ui/react';

import { promoContentStyles } from '@app/modules/common-ui/components/revenue-share-management/styles';
import { IPromoContentProps } from './interfaces';
import copyTextIcon from '@assets/images/copyTextIcon.svg';
import { useQuery } from '@app/modules/core/hooks';
import { IGetPromoContentRes } from '@app/modules/core/aws/lambdas/get-promo-content/interfaces';
import getPromoContent from '@app/modules/core/aws/lambdas/get-promo-content';
import { base64ToArrayBuffer } from '@app/modules/core/lvedHelpers/commontools';

export function PromoContent({ promoCode, affiliateUsername }: IPromoContentProps): ReactElement {
  const canDownload = promoCode !== '********';
  const {
    submit: handleGenerateContent,
    res: promoContent,
    isLoading: isBusy,
    err: error,
  } = useQuery<void, IGetPromoContentRes>(getPromoContent);

  useEffect(() => {
    if (promoContent?.fileDataBase64) {
      const { fileDataBase64 } = promoContent;
      const fileData = base64ToArrayBuffer(fileDataBase64);
      const fileAsBlob = new Blob([fileData], { type: 'application/zip' });
      const url = window.URL.createObjectURL(fileAsBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `LVED-${affiliateUsername}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  }, [promoContent]);

  return (
    <Grid
      {...(affiliateUsername
        ? promoContentStyles.gridContainerWSignupLink
        : promoContentStyles.gridContainerWOSignupLink)}
    >
      <GridItem area='promoCode' {...promoContentStyles.gridItemBox}>
        <HStack width='100%'>
          <Text {...promoContentStyles.promoTitle}>Promocode</Text>
          <Tooltip
            label={
              canDownload
                ? 'Copy to clipbaord'
                : 'Create an offer, then you can get your promo code'
            }
          >
            <HStack
              {...promoContentStyles.editBox}
              onClick={() => {
                navigator.clipboard.writeText(promoCode);
              }}
            >
              <Text {...promoContentStyles.promoCode}>{promoCode}</Text>
              <Spacer />
              <Image {...promoContentStyles.copyImage} alt='copy to clipboard' src={copyTextIcon} />
            </HStack>
          </Tooltip>
        </HStack>
      </GridItem>
      <GridItem area='download' {...promoContentStyles.gridItemBox}>
        <HStack w='100%' justifyContent={{ base: 'center', lg: 'right' }}>
          <Tooltip
            label={
              // 'Resources are still in development, we will update you when they are available'
              canDownload ? '' : "Resources can't be downloaded until you have an active promocode"
            }
          >
            <Button
              {...promoContentStyles.button}
              isDisabled={!canDownload}
              isLoading={isBusy}
              onClick={() => {
                handleGenerateContent();
              }}
            >
              {error ? 'DOWNLOAD' : 'DOWNLOAD RESOURCES'}
            </Button>
          </Tooltip>
          {error && <Text {...promoContentStyles.errorText}>{error.message}</Text>}
        </HStack>
      </GridItem>
      {affiliateUsername && (
        <GridItem area='signupLink' {...promoContentStyles.gridItemBox}>
          <HStack>
            <Text {...promoContentStyles.promoTitle}>Signup Link</Text>
            <Tooltip
              label={
                canDownload
                  ? 'Copy to clipbaord'
                  : 'Create an offer, then you can get your promo code'
              }
            >
              <HStack
                {...promoContentStyles.editBox}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://app.lved.com/partner/${affiliateUsername}`
                  );
                }}
              >
                <Text {...promoContentStyles.promoCode} width={{ base: '220px', lg: '100%' }}>
                  {`https://app.lved.com/partner/${affiliateUsername}`}
                </Text>
                <Spacer />
                <Image
                  {...promoContentStyles.copyImage}
                  alt='copy to clipboard'
                  src={copyTextIcon}
                />
              </HStack>
            </Tooltip>
          </HStack>
        </GridItem>
      )}
    </Grid>
  );
}
