import SignIn from './pages/sign-in';
import SignUp from './pages/sign-up';
import DashBoard from './pages/dashboard';
import OnBoarding from './pages/affiliate-onboarding';
import Welcome from './pages/welcome';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import Profile from './pages/profile';

import { RouteType } from './modules/core/routing/interfaces';

import { APP_ROUTES } from './modules/core/constants';
import SignupVerification from './pages/signup-verification/SignupVerification';

const routes: RouteType[] = [
  {
    path: APP_ROUTES.root,
    redirect: APP_ROUTES.dashboard,
  },
  {
    path: APP_ROUTES.signIn,
    component: SignIn,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.signUp,
    component: SignUp,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.resetPassword,
    component: ForgotPassword,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.signUpVerification,
    component: SignupVerification,
    isAuthDisallowed: true,
  },
  {
    path: APP_ROUTES.dashboard,
    component: DashBoard,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: true,
  },
  {
    path: APP_ROUTES.onboarding,
    component: OnBoarding,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: true,
  },
  {
    path: APP_ROUTES.welcome,
    component: Welcome,
    isAuthProtected: true,
    isDisallowedNoneLicensedUser: true,
  },
  {
    path: APP_ROUTES.profile,
    component: Profile,
    isAuthProtected: true,
  },
  {
    path: APP_ROUTES.isNotExist,
    redirect: APP_ROUTES.dashboard,
  },
];

export default routes;
