import React, { useState, useEffect, ReactElement } from 'react';

import {
  CognitoUser,
  CognitoUserSession,
  ICognitoUserAttributeData,
  IMfaSettings,
  ISignUpResult,
  UserData,
} from 'amazon-cognito-identity-js';
import { Hub, Auth } from 'aws-amplify'; // Logger

import { userPool as cognitoUserPool } from '@modules/core/aws/cognito';
import { getUserProfile as getDynamoDbUserProfile } from '@modules/core/aws/dynamo-db/user-profile';
import { linkUser as linkUserLambda } from '@modules/core/aws/lambdas/link-user';
import { UserProfileisNotExistError } from '@modules/core/errors';
import { EntityProfileType } from '@modules/core/aws/dynamo-db/user-profile/interfaces';
import { authContext as AuthContext } from './auth-context';
import {
  IAuthContextProviderProps,
  CognitoSignInType,
  CognitoSessionType,
  CognitoSignOutType,
  CognitoSignUpType,
  CognitoResendCodeType,
  CognitoSubmitMFACodeType,
  CognitoEmailVerificationType,
  CognitoAssociateSWTokenType,
  CognitoVerifySWTokenType,
  UserMFAPreferencesType,
  IMFAPreferences,
  SetUserMFAPreferenceType,
  SetMobileNoType,
  ISetMobileNoData,
  CognitoVerifyPhoneNoWithCodeType,
  CognitoSignupVerificationType,
} from './interfaces';

import { EVENTS, EVENTS_CHANNELS } from '@modules/core/constants';
import { syncProfile } from '../../aws/lambdas/profile';

function AuthContextProvider(props: IAuthContextProviderProps): ReactElement {
  const [authenticatedUser, setAuthenticatedUser] = useState<EntityProfileType | null>(null);
  const [tempUser, setTempUser] = useState<CognitoUser | null>(null);
  const [isUserLoading, setUserLoading] = useState<boolean>(true);

  const fetchAuthUser = async (): Promise<void> => {
    try {
      setUserLoading(true);

      const userProfile: EntityProfileType = await getDynamoDbUserProfile();
      // const mappedUser: UserType = userMapper(userProfile);

      return setAuthenticatedUser(userProfile);
    } catch (err) {
      if (err instanceof UserProfileisNotExistError) {
        // console.log('User not found, linking user then trying again...');
        try {
          await linkUserLambda();
          return await fetchAuthUser();
        } catch (err2) {
          console.log('Error linking user');
        }
      }
      // const user = await Auth.currentAuthenticatedUser();
      // console.log(user);
      return setAuthenticatedUser(null);
    } finally {
      setUserLoading(false);
    }
  };

  const confirmSignup: CognitoSignupVerificationType = ({
    verificationCode,
    userName,
  }): Promise<string> => {
    return Auth.confirmSignUp(userName, verificationCode);
  };

  const verifyEmail: CognitoEmailVerificationType = ({ verificationCode }): Promise<string> => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();

    return new Promise<string>((resolve, reject): void => {
      if (user) {
        user.getSession((): void => {});
        user.verifyAttribute('email', verificationCode, {
          onSuccess: () => {
            syncProfile().then(
              () => {
                resolve('success');
              },
              (reason: any) => {
                reject(reason);
              }
            );
          },
          onFailure: (err: Error) => {
            // console.log(`Unable to verify phone number: ${err}`);
            return reject(err);
          },
        });
      } else {
        reject(new Error(`User is not signed in`));
      }
    });
  };

  const signOut: CognitoSignOutType = () => Auth.signOut();

  const resendCode: CognitoResendCodeType = (email): Promise<void> => Auth.resendSignUp(email);

  const submitMFACode: CognitoSubmitMFACodeType = async (data): Promise<void> => {
    if (tempUser == null) {
      return;
    }
    setTempUser(
      await Auth.confirmSignIn(
        tempUser,
        data.code,
        data.source === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : 'SMS_MFA'
      )
    );
    await fetchAuthUser();
  };

  const signIn: CognitoSignInType = async ({ userName, password }): Promise<void> => {
    try {
      setTempUser(await Auth.signIn({ username: userName, password }));
      // console.log(tempUser);
      if (tempUser?.challengeName === 'SMS_MFA') {
        // console.log('MFA needed for user');
      } else {
        // console.log(`MFA may be needed for user: ${tempUser?.challengeName ?? 'None'}`);
        await fetchAuthUser();
      }
    } catch (err) {
      setTempUser(null);
      throw err;
    }
  };

  const signUp: CognitoSignUpType = ({
    email,
    userName,
    password,
    companyName,
    referrer,
    phoneNumber,
  }): Promise<ISignUpResult> => {
    // console.log('Attempting to sign up...');
    return Auth.signUp({
      username: userName,
      password,
      attributes: {
        email: email.toLowerCase(),
        'custom:entityName': companyName,
        'custom:referrer': referrer,
        phone_number: phoneNumber.replace(/\s/g, '').replaceAll('-', ''),
      },
    });
  };

  const setMobileNo: SetMobileNoType = async ({ mobileNo }: ISetMobileNoData): Promise<boolean> => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();

    return new Promise<boolean>((resolve, reject): void => {
      if (user) {
        user.getSession((): void => {});
        user.updateAttributes(
          [
            {
              Name: 'phone_number',
              Value: mobileNo.replace(/\s/g, '').replaceAll('-', ''),
            },
          ],
          (err?: Error | undefined) => {
            if (err) {
              reject(err);
            } else {
              user.getAttributeVerificationCode('phone_number', {
                onSuccess: (success: string) => {
                  resolve(success === 'SUCCESS');
                },
                onFailure(err2) {
                  reject(err2);
                },
              });
              // resolve(true);
            }
          }
        );
      } else {
        reject(new Error(`User is not signed in`));
      }
    });
  };

  const getSession: CognitoSessionType = (): CognitoUserSession | null => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();

    if (user) {
      user.getSession((): void => {});
      return user.getSignInUserSession();
    }

    return null;
  };

  const associateSWToken: CognitoAssociateSWTokenType = async (): Promise<string> => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();

    return new Promise<string>((resolve, reject): void => {
      if (user) {
        user.getSession((): void => {});
        user.associateSoftwareToken({
          associateSecretCode(secretCode: string) {
            // console.log(`Token: ${secretCode}`);
            return resolve(secretCode);
          },
          onFailure(err: any) {
            // console.log(`Unable to begin TOTP association: ${err}`);
            return reject(new Error(`Unable to begin TOTP association: ${err}`));
          },
        });
      } else {
        reject(new Error(`User is not signed in`));
      }
    });
  };

  const setMFAPreference: SetUserMFAPreferenceType = async (
    isSMSEnabled: boolean,
    isTOTPEnabled: boolean,
    isSMSPreferred: boolean
  ): Promise<boolean> => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();
    return new Promise<boolean>((resolve, reject): void => {
      if (user) {
        user.getSession((): void => {});
        user.setUserMfaPreference(
          {
            PreferredMfa: isSMSPreferred,
            Enabled: isSMSEnabled,
          } as IMfaSettings,
          {
            PreferredMfa: !isSMSPreferred && isTOTPEnabled,
            Enabled: isTOTPEnabled,
          } as IMfaSettings,
          (err?: Error) => {
            if (err) {
              // console.log(`Error setting MFA preference: ${err}`);
              reject(err);
            } else {
              // console.log(`Set MFA Preference with result: ${result}`);
              resolve(true);
            }
          }
        );
      } else {
        reject(new Error('User is not logged in'));
      }
    });
  };

  const getMFAPreference: UserMFAPreferencesType = async (): Promise<IMFAPreferences> => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();
    let phoneNumber: string | undefined;
    let isPhoneVerified: boolean | undefined;

    return new Promise<IMFAPreferences>((resolve, reject): void => {
      if (user) {
        user.getSession((): void => {
          user.getUserData((err?: Error, result?: UserData) => {
            if (err) {
              reject(new Error(`Unable to get user preferences: ${err}`));
            } else if (result) {
              // console.log('User data result: ');
              // console.log(result);
              result.UserAttributes.forEach((item: ICognitoUserAttributeData) => {
                if (item.Name === 'phone_number') {
                  phoneNumber = item.Value;
                }
                if (item.Name === 'phone_number_verified') {
                  isPhoneVerified = item.Value === 'true';
                }
              });

              const mfaPreferences = {
                isSMSEnabled:
                  result.UserMFASettingList?.find((value) => {
                    return value === 'SMS_MFA';
                  }) === 'SMS_MFA' ?? false,
                isTOTPEnabled:
                  result.UserMFASettingList?.find((value) => {
                    return value === 'SOFTWARE_TOKEN_MFA';
                  }) === 'SOFTWARE_TOKEN_MFA' ?? false,
                preferredMFASource: result.PreferredMfaSetting ?? 'NONE',
                phoneNumber,
                isPhoneVerified,
              };
              if (!result.PreferredMfaSetting) {
                if (mfaPreferences.isSMSEnabled) mfaPreferences.preferredMFASource = 'SMS_MFA';
                else if (mfaPreferences.isTOTPEnabled)
                  mfaPreferences.preferredMFASource = 'SOFTWARE_TOKEN_MFA';
                else mfaPreferences.preferredMFASource = 'NONE';
              }
              // console.log(mfaPreferences);
              resolve(mfaPreferences as IMFAPreferences);
            } else {
              reject(new Error('No data returned in query for user preferences'));
            }
          });
        });
      }
    });
  };

  const verifyPhoneNoWithCode: CognitoVerifyPhoneNoWithCodeType = async (
    code: string
  ): Promise<boolean> => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();

    return new Promise<boolean>((resolve, reject): void => {
      if (user) {
        user.getSession((): void => {});
        user.verifyAttribute('phone_number', code, {
          onSuccess: () => {
            syncProfile().then(
              () => {
                resolve(true);
              },
              (reason: any) => {
                reject(reason);
              }
            );
          },
          onFailure: (err: Error) => {
            // console.log(`Unable to verify phone number: ${err}`);
            return reject(err);
          },
        });
      } else {
        reject(new Error(`User is not signed in`));
      }
    });
  };

  const verifySWToken: CognitoVerifySWTokenType = async (
    token: string,
    deviceName: string
  ): Promise<boolean> => {
    const user: CognitoUser | null = cognitoUserPool.getCurrentUser();

    return new Promise<boolean>((resolve, reject): void => {
      if (user) {
        user.getSession((): void => {});
        user.verifySoftwareToken(token, deviceName, {
          onSuccess: () => {
            setMFAPreference(false, true, false).then(
              (success: boolean) => {
                resolve(success);
              },
              (err: Error) => {
                reject(err);
              }
            );
          },
          onFailure: (err: Error) => {
            // console.log(`Unable to verify token: ${err}`);
            return reject(new Error(`Unable to verify token: ${err}`));
          },
        });
      } else {
        reject(new Error(`User is not signed in`));
      }
    });
  };

  useEffect(() => {
    fetchAuthUser();

    const authListener: () => void = Hub.listen(
      EVENTS_CHANNELS.auth,
      async ({ payload: { event } }): Promise<void> => {
        switch (event) {
          case EVENTS.signIn:
            break;
          case EVENTS.signOut:
            setAuthenticatedUser(null);
            setTempUser(null);
            break;
          case EVENTS.signInFailure:
          case EVENTS.signUpFailure:
            if (authenticatedUser) {
              setAuthenticatedUser(null);
              setTempUser(null);
            }
            break;
          case EVENTS.signUp:
          case EVENTS.forgotPassword:
          case EVENTS.forgotPasswordSubmit:
          case EVENTS.forgotPasswordSubmitFailure:
          case EVENTS.forgotPasswordFailure:
            break;
          default:
            await fetchAuthUser();
        }
      }
    );

    // cleanup
    return () => {
      authListener();
    };
  }, []);

  const extendedUser = tempUser as CognitoUser & {
    challengeParam?: { CODE_DELIVERY_DESTINATION?: string };
  };
  const MFAChallengeDestination = extendedUser?.challengeParam?.CODE_DELIVERY_DESTINATION;

  return (
    <AuthContext.Provider
      value={{
        user: authenticatedUser,
        isAuthenticated: !!authenticatedUser,
        isAuthenticating: isUserLoading,
        needsMFACode:
          (tempUser?.challengeName === 'SMS_MFA' ||
            tempUser?.challengeName === 'SOFTWARE_TOKEN_MFA') ??
          false,
        codeDestination: MFAChallengeDestination ?? 'SOFTWARE_TOKEN_MFA',

        reloadUser: fetchAuthUser,

        signUp,
        signIn,
        submitMFACode,
        signOut,
        getSession,
        verifyEmail,
        confirmSignup,
        resendCode,
        associateSWToken,
        verifySWToken,
        setMFAPreference,
        getMFAPreference,
        setMobileNo,
        verifyPhoneNoWithCode,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
