import React, { ReactElement, useEffect } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { Box, Heading, Text, Button, Flex, VStack } from '@chakra-ui/react';

import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { QuestionsItemType } from '@app/modules/core/aws/dynamo-db/affiliate-onboarding/interfaces';
import { useAuth, useQuery } from '@modules/core/hooks';
import { getQuestions } from '@app/modules/core/aws/dynamo-db/affiliate-onboarding';

import Loader from '@modules/common-ui/components/global-loader';

import { welcomeStyles } from './styles';

import { APP_ROUTES } from '@modules/core/constants';

function Welcome(): ReactElement {
  const { user }: IAuthContext = useAuth();
  const navigateTo: NavigateFunction = useNavigate();
  const { isLoading, submit, res: questionsItem } = useQuery<void, QuestionsItemType>(getQuestions);

  const buttonClickHandler = () => {
    navigateTo(APP_ROUTES.onboarding, {
      state: {
        questionsItem,
      },
    });
  };

  useEffect(() => {
    if (!(user?.needsOnboarding ?? false)) navigateTo(APP_ROUTES.dashboard);
  }, [user]);

  useEffect(() => {
    submit();
  }, []);

  return (
    <Box>
      {!user ? (
        <Loader />
      ) : (
        <Flex flexDirection='column' {...welcomeStyles.container}>
          <VStack {...welcomeStyles.welcomeTextBox}>
            <Text as='b'>INTRODUCTION</Text>

            <Heading>Congratulations on your new account for {user.entityName}</Heading>

            <Text {...welcomeStyles.welcomeAdditionalText}>
              We are looking forward to working with you!
            </Text>
          </VStack>

          <Flex flexDirection='column' {...welcomeStyles.getStartedBox}>
            <Text>To get started we need to compile some basic information.</Text>

            <Button
              isLoading={isLoading}
              onClick={buttonClickHandler}
              {...welcomeStyles.getStartedButton}
            >
              Let&apos;s get started
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

export default Welcome;
