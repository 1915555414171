import React, { ReactElement } from 'react';

import { VStack } from '@chakra-ui/react';

import ProfileBasicInfo from '@app/modules/common-ui/components/revenue-share-management';

import { profileStyles } from '@pages/profile/styles';

// import ManageMFAModal from '@app/modules/common-ui/components/mfa-management/ManageMFAModal';

function Profile(): ReactElement {
  return (
    <VStack {...profileStyles.mainContainer}>
      <ProfileBasicInfo />
    </VStack>
  );
}

export default Profile;
