import React, { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { useAuth } from '../hooks';
import { IPrivateRouteProps } from './interfaces';
import { IAuthContext } from '../contexts/auth/interfaces';

import { APP_ROUTES } from '../constants';

function PrivateRoute({
  isAuthDisallowed,
  isAuthProtected,
  children,
}: IPrivateRouteProps): ReactElement {
  // const navigateTo = useNavigate();
  const { user, getSession }: IAuthContext = useAuth();
  const session: CognitoUserSession | null = getSession();

  useEffect(() => {
    /* if (user) {
      if (!user.licenseStatus) {
        // want something else to key off of...
        navigateTo(APP_ROUTES.profile);
      }
    } */
  }, [user]);

  if (session && isAuthDisallowed) {
    return <Navigate to={APP_ROUTES.root} />;
  }

  if (!session && isAuthProtected) {
    return <Navigate to={APP_ROUTES.signIn} />;
  }

  return children;
}

export default PrivateRoute;
