import React, { ReactElement, useEffect } from 'react';

import { VStack, Text, HStack, useDisclosure, Box, Heading, Wrap, Link } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

import { useAuth } from '@modules/core/hooks';
import { Section } from '@modules/common-ui/components/section';
import { Avatar } from './Avatar';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { EditUserProfileModal } from '@modules/common-ui/components/modal';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';

import { profileBasicInfoStyles } from './styles';
import { formatUSPhoneNumber } from '@app/modules/core/lvedHelpers/commontools';

function ProfileBasicInfo(): ReactElement {
  const { user, isAuthenticating, reloadUser }: IAuthContext = useAuth();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  useEffect(() => {
    // console.log('Loading user...');
    reloadUser();
  }, []);

  const getAddress = (): string => {
    if (!user?.address) return '';

    return `${user.address.Street1 ?? ''}${user.address.Street2 ?? ''}, ${
      user.address.City ?? ''
    } ${user.address.State ?? ''} ${user.address.Zip ?? ''}`;
  };

  return (
    <Box {...profileBasicInfoStyles.container}>
      <EditUserProfileModal isOpen={isModalOpen} onClose={onModalClose} />

      <Section title='Affiliate Account Details' isLoading={isAuthenticating}>
        <ConditionSeparator
          condition={!!user}
          target={
            <VStack {...profileBasicInfoStyles.contentBox}>
              <Wrap {...profileBasicInfoStyles.avatarIconBox}>
                <Avatar file={user?.logo} />
                <VStack>
                  <Heading>{user?.entityName}</Heading>
                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Affiliate type:</Text>
                    <Text {...profileBasicInfoStyles.greenText}>{user?.affiliateType}</Text>
                  </HStack>
                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Account status:</Text>
                    <Text {...profileBasicInfoStyles.greenText}>{user?.accountStatus}</Text>
                  </HStack>
                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Website:</Text>
                    <Link
                      {...profileBasicInfoStyles.greenText}
                      href={`http://${user?.website ?? ``}`}
                    >
                      {user?.website ?? 'N/A'}
                    </Link>
                  </HStack>
                  {user?.pendingUpdates && (
                    <Text color='red'>
                      You&apos;ve requested changes that are pending LVED&apos;s review
                    </Text>
                  )}
                </VStack>
                <EditIcon onClick={onModalOpen} {...profileBasicInfoStyles.editIcon} />
              </Wrap>

              <Box {...profileBasicInfoStyles.separator} />

              <Wrap {...profileBasicInfoStyles.userInfoContainer}>
                <VStack spacing='10'>
                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Primary Contact Email:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>
                      {user?.primaryContactEmail || 'no info'}
                    </Text>
                  </HStack>

                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Primary Contact Phone:</Text>
                    <Text {...profileBasicInfoStyles.greenText}>
                      {formatUSPhoneNumber(user?.primaryContactPhone || 'no info')}
                    </Text>
                  </HStack>

                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Address:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>{getAddress() || 'no info'}</Text>
                  </HStack>
                </VStack>
              </Wrap>
            </VStack>
          }
          defaultTarget={<Text>User not found...</Text>}
        />
      </Section>
    </Box>
  );
}

export default ProfileBasicInfo;
