export const signUpVerificationStyles = {
  container: {
    align: 'center',
    justifyContent: 'center',
    // h: '100vh',
    maxWidth: '1320px',
    margin: '0 auto',
  },

  verificationBox: {
    bg: 'white',
    rounded: 'md',
    w: { base: '95%', lg: '574px' },
    padding: { base: '15px 15px', lg: '40px 60px' },
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.160784)',
  },

  verifyButton: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',
    height: '56px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  resendButton: {
    colorScheme: 'blackAlpha',
    width: 'full',
  },

  contentBox: {
    spacing: '4',
    align: 'flex-start',
  },

  textWrapper: {
    display: 'flex',
    pb: '16px',
  },

  verificationCodeInput: {
    marginBottom: '25px !important',
  },

  textError: {
    color: 'brand.error',
  },

  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'full',
  },
};
