import React, { ReactElement } from 'react';

import { Text, Grid, GridItem, VStack, Center } from '@chakra-ui/react';
import { IBequestSummary } from './interfaces';
import { bequestSummaryStyles } from './styles';
import { formatCommaSeperatedNumber, formatPrice } from '@app/modules/core/lvedHelpers/commontools';

export function BequestSummary({ bequestData }: IBequestSummary): ReactElement {
  return (
    <VStack {...bequestSummaryStyles.container}>
      <Text {...bequestSummaryStyles.title}>Non-profit Summary</Text>
      {bequestData?.error && <Text {...bequestSummaryStyles.errorText}>{bequestData!.error}</Text>}
      <Grid {...bequestSummaryStyles.gridContainer}>
        <GridItem area='h1' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableHeader}>Bequest Summary</Center>
        </GridItem>
        <GridItem area='h2' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableHeader}>Latest Wishes</Center>
        </GridItem>
        <GridItem area='h3' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableHeader}>Signed Documents</Center>
        </GridItem>
        <GridItem area='r11' {...bequestSummaryStyles.gridItem}>
          <Text {...bequestSummaryStyles.rowTitle}>Individuals leaving you bequests:</Text>
        </GridItem>
        <GridItem area='r12' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatCommaSeperatedNumber(bequestData?.totalPendingBequestors ?? 0)}
          </Center>
        </GridItem>
        <GridItem area='r13' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatCommaSeperatedNumber(bequestData?.totalBequestors ?? 0)}
          </Center>
        </GridItem>
        <GridItem area='r21' {...bequestSummaryStyles.gridItem}>
          <Text {...bequestSummaryStyles.rowTitle}>Cash bequested:</Text>
        </GridItem>
        <GridItem area='r22' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatPrice(bequestData?.totalPendingCashBequests ?? 0, true)}
          </Center>
        </GridItem>
        <GridItem area='r23' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatPrice(bequestData?.totalCashBequests ?? 0, true)}
          </Center>
        </GridItem>
        <GridItem area='r31' {...bequestSummaryStyles.gridItem}>
          <Text {...bequestSummaryStyles.rowTitle}>Property bequested:</Text>
        </GridItem>
        <GridItem area='r32' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatCommaSeperatedNumber(bequestData?.totalPendingPropertyBequests ?? 0)}
          </Center>
        </GridItem>
        <GridItem area='r33' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatCommaSeperatedNumber(bequestData?.totalPropertyBequests ?? 0)}
          </Center>
        </GridItem>
        <GridItem area='r41' {...bequestSummaryStyles.gridItem}>
          <Text {...bequestSummaryStyles.rowTitle}>
            Individuals leaving a portion of their remainder estate:
          </Text>
        </GridItem>
        <GridItem area='r42' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatCommaSeperatedNumber(
              bequestData?.totalPendingRemainderDistributionBequests ?? 0
            )}
          </Center>
        </GridItem>
        <GridItem area='r43' {...bequestSummaryStyles.gridItem}>
          <Center {...bequestSummaryStyles.tableValue}>
            {formatCommaSeperatedNumber(bequestData?.totalRemainderDistributionBequests ?? 0)}
          </Center>
        </GridItem>
      </Grid>
    </VStack>
  );
}

/*
<TableContainer>
        <Table layout='fixed' variant='unstyled' size='sm'>
          <Thead>
            <Tr borderBottom='1px'>
              <Th {...bequestSummaryStyles.tableCol1}>
                <Text>Bequest Summary</Text>
              </Th>
              <Th {...bequestSummaryStyles.tableCol}>Latest Wishes</Th>
              <Th {...bequestSummaryStyles.tableCol}>Signed Will</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr borderBottom='0px'>
              <Td {...bequestSummaryStyles.rowTitle}>
                Individuals leaving you bequests:
              </Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatCommaSeperatedNumber(bequestData?.totalPendingBequestors ?? 0)}
              </Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatCommaSeperatedNumber(bequestData?.totalBequestors ?? 0)}
              </Td>
            </Tr>
            <Tr>
              <Td {...bequestSummaryStyles.rowTitle}>Cash bequested:</Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatPrice(bequestData?.totalPendingCashBequests ?? 0, true)}
              </Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatPrice(bequestData?.totalCashBequests ?? 0, true)}
              </Td>
            </Tr>
            <Tr>
              <Td {...bequestSummaryStyles.rowTitle}>Property bequested:</Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatCommaSeperatedNumber(bequestData?.totalPendingPropertyBequests ?? 0)}
              </Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatCommaSeperatedNumber(bequestData?.totalPropertyBequests ?? 0)}
              </Td>
            </Tr>
            <Tr>
              <Td {...bequestSummaryStyles.rowTitle}>
                Individuals leaving a portion of their remainder estate:
              </Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatCommaSeperatedNumber(
                  bequestData?.totalPendingRemainderDistributionBequests ?? 0
                )}
              </Td>
              <Td {...bequestSummaryStyles.tableValue}>
                {formatCommaSeperatedNumber(bequestData?.totalRemainderDistributionBequests ?? 0)}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
*/

export default BequestSummary;
