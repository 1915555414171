import * as Yup from 'yup';

import {
  PASSWORD_VALIDATION_ERRORS,
  USERNAME_VALIDATION_ERRORS,
  MFA_VALIDATION_ERRORS,
} from '@modules/core/constants';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(PASSWORD_VALIDATION_ERRORS.required),
  userName: Yup.string()
    .trim()
    .required(USERNAME_VALIDATION_ERRORS.required)
    .min(6, USERNAME_VALIDATION_ERRORS.invalidLength)
    .matches(/^[A-Za-z0-9]+$/, USERNAME_VALIDATION_ERRORS.invalidCharacters),
});

export const mfaValidationSchema = Yup.object().shape({
  code: Yup.string().required(MFA_VALIDATION_ERRORS.required),
});

export default validationSchema;
