import React, { ReactElement } from 'react';

import { VStack, Text, Wrap, Spinner } from '@chakra-ui/react';

import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';

import { profileSummaryStyles } from './styles';
import { Avatar } from './Avatar';
import { formatUSPhoneNumber } from '@app/modules/core/lvedHelpers/commontools';
import { IProfileSummaryProps } from './interfaces';

export function ProfileSummary({ user }: IProfileSummaryProps): ReactElement {
  return (
    <ConditionSeparator
      condition={!!user}
      target={
        <Wrap w='100%' p='0px 15px 0px 15px'>
          <Avatar file={user?.logo} />
          <VStack alignItems='left' spacing='0px'>
            <Text {...profileSummaryStyles.entityName}>{user?.entityName}</Text>
            <Text {...profileSummaryStyles.entityDetails}>Type: {user?.affiliateType}</Text>
            <Text {...profileSummaryStyles.entityDetails}>
              {user?.registeredState ?? 'Registration unknown'}
            </Text>
            <Text {...profileSummaryStyles.entityDetailsClickable}>{user?.website}</Text>
            <Text {...profileSummaryStyles.entityDetailsClickable}>
              {user?.primaryContactEmail}
            </Text>
            <Text {...profileSummaryStyles.entityDetails}>
              {formatUSPhoneNumber(user?.primaryContactPhone ?? 'Not available')}
            </Text>
          </VStack>
        </Wrap>
      }
      defaultTarget={<Spinner />}
    />
  );
}
