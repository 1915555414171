import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IAffiliateDashboardMetricsRes,
} from '@modules/core/aws/lambdas/dashboard/interfaces';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function getDashboardMetrics(): Promise<IAffiliateDashboardMetricsRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IAffiliateDashboardMetricsRes>(credentials, {
    FunctionName: AWS_LAMBDAS.affiliateDashboard,
  });
}
