import React, { ReactElement, useEffect } from 'react';

import { Button, Spinner, VStack } from '@chakra-ui/react';
import { useQueryToast, useAuth } from '@app/modules/core/hooks';
import { updateUserProfile } from '@app/modules/core/aws/dynamo-db/user-profile';

import { EditUserProfileModalPropsType } from '@modules/common-ui/components/modal/interfaces';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';

import Modal from '@modules/common-ui/components/modal/ModalBasic';

import { editUserProfileModalStyles } from '@modules/common-ui/components/modal/styles';
import { ConditionSeparator } from '../condition-separator';
import { useFormik } from 'formik';
import { AddressInput, PhoneInput, TextInput, ThumbnailUploadInput } from '../text-field';
import { EntityProfileType } from '@app/modules/core/aws/dynamo-db/user-profile/interfaces';
import { buffersEqual } from '@app/modules/core/lvedHelpers/commontools';

function EditUserProfileModal({ onClose, isOpen }: EditUserProfileModalPropsType): ReactElement {
  const { user, reloadUser }: IAuthContext = useAuth();
  const { submit, isLoading, res } = useQueryToast(updateUserProfile);

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: {
        Street1: '',
        Street2: '',
        City: '',
        State: '',
        Zip: '',
        Country: '',
      },
      ...user,
    },
    onSubmit: (data: Record<string, any>) => {
      const updatedUserRecord = data as EntityProfileType;
      const trimmedInfo = {} as Record<string, any>;
      if (user?.EIN !== updatedUserRecord.EIN) trimmedInfo.EIN = updatedUserRecord.EIN;
      if (user?.entityName !== updatedUserRecord.entityName)
        trimmedInfo.entityName = updatedUserRecord.entityName;
      if (user?.registeredState !== updatedUserRecord.registeredState)
        trimmedInfo.registeredState = updatedUserRecord.registeredState;
      if (user?.primaryContactEmail !== updatedUserRecord.primaryContactEmail)
        trimmedInfo.primaryContactEmail = updatedUserRecord.primaryContactEmail
          .replace(/\s/g, '')
          .replaceAll('-', '');
      if (user?.primaryContactPhone !== updatedUserRecord.primaryContactPhone)
        trimmedInfo.primaryContactPhone = updatedUserRecord.primaryContactPhone;
      if (
        user?.address?.Street1 !== updatedUserRecord.address?.Street1 ||
        user?.address?.Street2 !== updatedUserRecord.address?.Street2 ||
        user?.address?.City !== updatedUserRecord.address?.City ||
        user?.address?.State !== updatedUserRecord.address?.State ||
        user?.address?.Zip !== updatedUserRecord.address?.Zip ||
        user?.address?.Country !== updatedUserRecord.address?.Country
      )
        trimmedInfo.address = updatedUserRecord.address;
      if (!buffersEqual(user?.logo, updatedUserRecord.logo))
        trimmedInfo.logo = updatedUserRecord.logo;
      if (user?.website !== updatedUserRecord.website)
        trimmedInfo.website = updatedUserRecord.website;
      return submit(trimmedInfo);
    },
  });

  useEffect(() => {
    if (res) {
      reloadUser();
      onClose();
    }
  }, [res]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Affiliate Profile' size='3xl'>
      <ConditionSeparator
        condition={!isLoading}
        target={
          <form onSubmit={handleSubmit}>
            <VStack {...editUserProfileModalStyles.contentBox}>
              <TextInput
                id='entityName'
                name='entityName'
                label='Your legal entity name'
                value={values.entityName}
                isError={!!errors.entityName && touched.entityName}
                errorMessage={errors.entityName}
                onChange={handleChange}
              />
              <ThumbnailUploadInput
                id='logo'
                name='logo'
                label="Your entity's logo ( 300x300 pixels )"
                isError={!!errors.logo && touched.logo}
                errorMessage={errors.logo}
                setValue={(bufArray) => {
                  const buf = bufArray as ArrayBuffer;
                  // const fileName = bufArray as string;
                  // console.log(`Setting logo with buffer of size: ${buf.byteLength ?? fileName.length ?? 0}`);
                  setFieldValue('logo', buf);
                }}
              />
              <TextInput
                id='website'
                name='website'
                label='Website URL'
                value={values.website}
                isError={!!errors.website && touched.website}
                errorMessage={errors.website}
                onChange={handleChange}
              />
              <PhoneInput
                id='primaryContactPhone'
                name='primaryContactPhone'
                label='Primary contact phone number'
                value={values.primaryContactPhone}
                isError={!!errors.primaryContactPhone && touched.primaryContactPhone}
                errorMessage={errors.primaryContactPhone}
                onChange={handleChange}
              />
              <TextInput
                id='primaryContactEmail'
                name='primaryContactEmail'
                type='email'
                label='Primary Contact Email Address'
                onChange={handleChange}
                value={values.primaryContactEmail}
                isError={!!errors.primaryContactEmail && touched.primaryContactEmail}
                errorMessage={errors.primaryContactEmail}
              />
              {values.address && (
                <AddressInput
                  values={values.address}
                  helperText='Headquarters Address'
                  setValue={setFieldValue}
                />
              )}
              <Button isLoading={isLoading} type='submit' {...editUserProfileModalStyles.button}>
                Save User Details
              </Button>
            </VStack>
          </form>
        }
        defaultTarget={<Spinner />}
      />
    </Modal>
  );
}

export default EditUserProfileModal;
